import { useState } from 'react';
import CloseIcon from 'src/images/close.svg?react';
import QuizConfirmQuit from 'src/components/Quiz/QuizConfirmQuit';

const QuizBackButton = ({ color = 'white' }: { color?: string }) => {
  const [openCancelQuiz, setOpenCancelQuiz] = useState(false);

  const onBackClick = () => {
    setOpenCancelQuiz(true);
  };

  return (
    <>
      <div className={`p-3 text-${color}`} onClick={onBackClick}>
        <CloseIcon
          className={`fill-${color} w-4 h-4`}
          data-testid={'btn-back'}
        />
      </div>
      <QuizConfirmQuit
        open={openCancelQuiz}
        onClose={() => {
          setOpenCancelQuiz(false);
        }}
      />
    </>
  );
};

export default QuizBackButton;
