import { useEffect, useMemo, useRef, useState } from 'react';
import { shuffle } from 'src/utils/helper-functions';
import QuizButton from 'src/components/Quiz/QuizButton';

import { QuestionStatus, useQuiz } from 'src/components/Quiz/QuizContext';
import QuizStatus from 'src/components/Quiz/QuizStatus';
import QuizMatchingAnswer from 'src/components/Quiz/MatchingQuestion/QuizMatchingAnswer';
import QuizGuideline from 'src/components/Quiz/QuizGuideline';
import { Haptics, NotificationType } from '@capacitor/haptics';
import { ImpactStyle } from '@capacitor/haptics/dist/esm/definitions';
import { MatchingQuestion } from 'src/types/quiz.types';

const MAX_ANSWERS = 4;

type AnswerPossibility = { label: string; value: string };
type SideAnswers = {
  correct: AnswerPossibility[];
  incorrect: AnswerPossibility[];
  selected: AnswerPossibility[];
};
const defaultAnswers: SideAnswers = {
  correct: [],
  incorrect: [],
  selected: [],
};

const QuizMatchingQuestion = ({ question }: { question: MatchingQuestion }) => {
  const { onAnswer, status } = useQuiz();
  const resetTimeout = useRef<number>();
  const [answers, setAnswers] = useState<{
    left: SideAnswers;
    right: SideAnswers;
  }>({
    left: defaultAnswers,
    right: defaultAnswers,
  });
  useEffect(() => {
    setAnswers({
      left: defaultAnswers,
      right: defaultAnswers,
    });
  }, [question.id]);

  const { leftAnswers, rightAnswers } = useMemo<{
    leftAnswers: AnswerPossibility[];
    rightAnswers: AnswerPossibility[];
  }>(() => {
    const matches = question.correct.slice(0, MAX_ANSWERS);
    const leftAnswers: AnswerPossibility[] = matches.flatMap(
      ([left], index) => ({
        label: left,
        value: `left-${index}-${left}`,
      }),
    );
    const rightAnswers: AnswerPossibility[] = matches.flatMap(
      ([, right], index) => ({
        label: right,
        value: `right-${index}-${right}`,
      }),
    );
    return {
      leftAnswers: shuffle(leftAnswers),
      rightAnswers: shuffle(rightAnswers),
    };
  }, [question.correct]);

  const onClickAnswer = (answer: AnswerPossibility, side: 'left' | 'right') => {
    const sideAnswers = side === 'left' ? answers.left : answers.right;
    const otherSideAnswers = side === 'left' ? answers.right : answers.left;
    const otherSideKey = side === 'left' ? 'right' : 'left';

    if (resetTimeout.current) {
      clearTimeout(resetTimeout.current);
      resetTimeout.current = undefined;
    }

    if (sideAnswers.selected.length) {
      Haptics.impact({ style: ImpactStyle.Light });

      if (
        sideAnswers.selected.some(selected => selected.value === answer.value)
      ) {
        setAnswers(answers => ({
          ...answers,
          [side]: {
            ...answers[side],
            selected: [],
          },
          [otherSideKey]: {
            ...answers[otherSideKey],
            selected: [],
          },
        }));
        return;
      }
      setAnswers(answers => ({
        ...answers,
        [side]: {
          ...answers[side],
          selected: [answer],
        },
        [otherSideKey]: {
          ...answers[otherSideKey],
          selected: [],
        },
      }));
      return;
    }

    if (otherSideAnswers.selected.length) {
      const isCorrect = question.correct.some(([left, right]) => {
        if (side === 'left') {
          return (
            left === answer.label &&
            right === otherSideAnswers.selected[0].label
          );
        }
        return (
          left === otherSideAnswers.selected[0].label && right === answer.label
        );
      });

      Haptics.notification({
        type: isCorrect ? NotificationType.Success : NotificationType.Error,
      });

      if (isCorrect) {
        setAnswers(answers => ({
          ...answers,
          [side]: {
            ...answers[side],
            selected: [],
            correct: [...answers[side].correct, answer],
          },
          [otherSideKey]: {
            ...answers[otherSideKey],
            selected: [],
            correct: [
              ...answers[otherSideKey].correct,
              otherSideAnswers.selected[0],
            ],
          },
        }));
        const isFullCorrect =
          sideAnswers.correct.length + 1 === leftAnswers.length;
        if (isFullCorrect) {
          onAnswer(question.correct, true);
        }
        return;
      }
      setAnswers(answers => ({
        ...answers,
        [side]: {
          ...answers[side],
          selected: [],
          incorrect: [answer],
        },
        [otherSideKey]: {
          ...answers[otherSideKey],
          selected: [],
          incorrect: [otherSideAnswers.selected[0]],
        },
      }));
      return;
    }
    Haptics.impact({ style: ImpactStyle.Light });
    setAnswers(answers => ({
      ...answers,
      [side]: {
        ...answers[side],
        selected: [answer],
        incorrect: [],
      },
      [otherSideKey]: {
        ...answers[otherSideKey],
        selected: [],
        incorrect: [],
      },
    }));
  };

  const isAnswerClickable = (
    answer: AnswerPossibility,
    side: 'right' | 'left',
  ) => {
    if (status !== QuestionStatus.WAITING) {
      return false;
    }
    const sideAnswers = side === 'left' ? answers.left : answers.right;
    const isCorrect = sideAnswers.correct.some(
      correct => correct.value === answer.value,
    );
    if (isCorrect) {
      return false;
    }
    return !sideAnswers.selected.some(
      selected => selected.value === answer.value,
    );
  };

  useEffect(() => {
    if (
      answers.left.incorrect.length > 0 ||
      answers.right.incorrect.length > 0
    ) {
      resetTimeout.current = window.setTimeout(() => {
        setAnswers(answers => ({
          ...answers,
          incorrectLeft: [],
          incorrectRight: [],
        }));
        resetTimeout.current = undefined;
      }, 1000);
    }
  }, [answers.left.incorrect.length, answers.right.incorrect.length]);

  return (
    <>
      <div
        className={
          'flex flex-col w-full gap-1 items-start h-full overflow-hidden'
        }
        data-testid={`question-${question.id}`}
      >
        <div
          className={'px-8 py-2 w-full flex-1 flex items-end justify-center'}
        >
          <QuizStatus />
        </div>
        <div
          className={
            'flex flex-col w-full items-center gap-6 bg-white py-6 rounded-t-xl overflow-auto'
          }
        >
          <div
            className={
              'w-full overflow-y-auto overflow-visible px-6 flex flex-col items-center gap-6'
            }
          >
            <QuizGuideline />
            <div className={'flex flex-row w-full'}>
              <div className={'w-[50%] pr-2'}>
                <div className={'flex flex-col gap-4'}>
                  {leftAnswers.map(answer => {
                    const isCorrect = answers.left.correct.includes(answer);
                    const isSelected = answers.left.selected.includes(answer);
                    const isIncorrect = answers.left.incorrect.includes(answer);
                    return (
                      <QuizMatchingAnswer
                        key={answer.value}
                        id={answer.label}
                        showCorrection={
                          isCorrect ||
                          isIncorrect ||
                          (status !== QuestionStatus.WAITING &&
                            status !== QuestionStatus.PAUSED)
                        }
                        isCorrect={isCorrect}
                        isSelected={isSelected || isCorrect || isIncorrect}
                        onClick={
                          isAnswerClickable(answer, 'left')
                            ? () => onClickAnswer(answer, 'left')
                            : undefined
                        }
                      />
                    );
                  })}
                </div>
              </div>
              <div className={'w-[50%] pl-2'}>
                <div className={'flex flex-col gap-4'}>
                  {rightAnswers.map(answer => {
                    const isCorrect = answers.right.correct.includes(answer);
                    const isSelected = answers.right.selected.includes(answer);
                    const isIncorrect =
                      answers.right.incorrect.includes(answer);
                    return (
                      <QuizMatchingAnswer
                        key={answer.value}
                        id={answer.label}
                        showCorrection={
                          isCorrect ||
                          isIncorrect ||
                          (status !== QuestionStatus.WAITING &&
                            status !== QuestionStatus.PAUSED)
                        }
                        isCorrect={isCorrect}
                        isSelected={isSelected || isCorrect || isIncorrect}
                        onClick={
                          isAnswerClickable(answer, 'right')
                            ? () => onClickAnswer(answer, 'right')
                            : undefined
                        }
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className={'w-full px-6'}>
            <QuizButton />
          </div>
        </div>
      </div>
    </>
  );
};

export default QuizMatchingQuestion;
