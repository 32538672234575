import { useMemo } from 'react';
import { shuffle } from 'remeda';
import { QuestionStatus, useQuiz } from 'src/components/Quiz/QuizContext';
import { WordPuzzleQuestion } from 'src/types/quiz.types';
import QuizWordPuzzleLetter from './QuizWordPuzzleLetter';
import { Letter } from 'src/components/Quiz/WordPuzzleQuestion/QuizWordPuzzleQuestion';

const QuizWordPuzzleLetters = ({
  selectedLetters,
  question,
  onClickLetter,
}: {
  question: WordPuzzleQuestion;
  selectedLetters: Letter[];
  onClickLetter: (letter: Letter) => void;
}) => {
  const { status } = useQuiz();
  const proposed_letters: Letter[] = useMemo(() => {
    const letters_in_word = question.correct.split('');
    const alphabet = 'abcdefghijklmnopqrstuvwxyz'
      .split('')
      .filter(letter => !letters_in_word.includes(letter));
    const wrong_letters = shuffle(alphabet).slice(
      0,
      Math.max(2, 10 - letters_in_word.length),
    );
    return shuffle([...letters_in_word, ...wrong_letters])
      .filter(letter => letter !== ' ')
      .map((letter, index) => ({
        letter,
        id: index,
      }));
  }, [question.correct]);

  return (
    <div
      className={'flex flex-row flex-wrap gap-3 justify-center items-center'}
    >
      {proposed_letters.map((letter, index) => (
        <QuizWordPuzzleLetter
          key={index}
          letter={letter}
          selectedLetters={selectedLetters}
          onClick={
            status === QuestionStatus.WAITING
              ? () => {
                  onClickLetter(letter);
                }
              : undefined
          }
        />
      ))}
    </div>
  );
};

export default QuizWordPuzzleLetters;
