import { QuizRun } from 'src/types/quiz.types';
import QuizResultHeader from 'src/components/Quiz/Result/QuizResultHeader';
import BlankWhiteLayout from 'src/layouts/BlankWhiteLayout';
import { Lesson } from 'src/types/lesson.types';
import Button from 'src/components/common/designSystem/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useNavigateBack from 'src/hooks/useNavigateBack';
import PrimaryBox from 'src/components/common/PrimaryBox';
import QuizXP from 'src/components/Quiz/QuizXP';
import BaoWink from 'src/images/bao/bao-wink.svg?react';
import BaoNotBad from 'src/images/bao/bao-not-bad.svg?react';
import BaoSad from 'src/images/bao/bao-sad.svg?react';
import BaoQuizResultStars from 'src/components/Quiz/Result/BaoQuizResultStars';

const BaoQuizResult = ({
  quizRun,
  lesson,
}: {
  quizRun: QuizRun;
  lesson: Lesson;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goBack = useNavigateBack();

  const handleRestart = async () => {
    navigate(`/lessons/${lesson.id}/quizzes/${quizRun.quiz_id}`, {
      replace: true,
    });
  };

  const getTitle = () => {
    if (quizRun.grade >= 70) {
      return t('quiz.openQuestion.good');
    } else if (quizRun.grade >= 35) {
      return t('quiz.openQuestion.notBad');
    } else {
      return t('quiz.openQuestion.bad');
    }
  };

  const getBao = () => {
    if (quizRun.grade >= 70) {
      return <BaoWink className={'w-auto h-40'} />;
    } else if (quizRun.grade >= 35) {
      return <BaoNotBad className={'w-auto h-40'} />;
    } else {
      return <BaoSad className={'w-auto h-40'} />;
    }
  };

  return (
    <BlankWhiteLayout>
      <div className={'w-full h-full flex flex-col justify-between'}>
        <QuizResultHeader title={lesson.title} />

        <div className={'flex flex-col justify-center items-center gap-4 p-4'}>
          <div className={'text-3xl font-bold'}>{getTitle()}</div>
          <div className={'flex flex-row gap-4 h-14'}>
            <BaoQuizResultStars grade={quizRun.grade} />
          </div>
          <div>{getBao()}</div>
          <PrimaryBox
            data-testid={'quiz-xp'}
            className={'flex flex-row items-center justify-between gap-2'}
          >
            <div className={'text-base font-bold uppercase'}>
              {t('quiz.result.xp')}
            </div>
            <QuizXP xp={quizRun.xp_won} />
          </PrimaryBox>
        </div>

        <div className={'flex flex-col gap-2 p-4'}>
          <Button type={'default'} data-testid="btn-quit-quiz" onClick={goBack}>
            {t('global.quit')}
          </Button>
          <Button
            type={'primary'}
            data-testid="btn-restart-quiz"
            onClick={handleRestart}
          >
            {t('quiz.result.restart')}
          </Button>
        </div>
      </div>
    </BlankWhiteLayout>
  );
};

export default BaoQuizResult;
