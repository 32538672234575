import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QuizCompleteSentenceQuestionHeader from './QuizCompleteSentenceQuestionHeader';
import Footer from 'src/components/common/designSystem/Footer';
import QuizButton from 'src/components/Quiz/QuizButton';

import { QuestionStatus, useQuiz } from 'src/components/Quiz/QuizContext';
import QuizCompleteSentencePhrase from 'src/components/Quiz/CompleteSentenceQuestion/QuizCompleteSentencePhrase';
import Button from 'src/components/common/designSystem/Button';
import QuizStatus from '../QuizStatus';
import QuizCompleteSentenceAnswers from 'src/components/Quiz/CompleteSentenceQuestion/QuizCompleteSentenceAnswers';
import { CompleteSentenceQuestion } from 'src/types/quiz.types';

const QuizCompleteSentenceQuestion = ({
  question,
}: {
  question: CompleteSentenceQuestion;
}) => {
  const { t } = useTranslation();
  const { onAnswer, status } = useQuiz();
  const [completions, setCompletions] = useState<string[]>([]);

  const onClickAnswer = (index: number) => {
    setCompletions(completions => {
      const newCompletions = [...completions];
      newCompletions.splice(index, 1);
      return newCompletions;
    });
  };

  useEffect(() => {
    setCompletions([]);
  }, [question]);

  const validateCompletions = async () => {
    const isCorrect = question.correct.every(
      (correctAnswer, index) => completions[index] === correctAnswer,
    );
    await onAnswer(completions, isCorrect);
  };

  return (
    <>
      <div
        className={
          'flex flex-col w-full justify-between gap-5 items-start h-full py-2'
        }
        data-testid={`question-${question.id}`}
      >
        <QuizCompleteSentenceQuestionHeader question={question} />
        <QuizCompleteSentencePhrase
          question={question}
          completions={completions}
          onClickCompletion={onClickAnswer}
        />
        <QuizCompleteSentenceAnswers
          completions={completions}
          question={question}
          onClickAnswer={answer => {
            setCompletions([...completions, answer]);
          }}
        />
      </div>
      <Footer>
        <QuizStatus />
        {status === QuestionStatus.WAITING ? (
          <Button
            type="primary"
            data-testid="btn-validate-answer"
            onClick={validateCompletions}
          >
            {t('quiz.validate')}
          </Button>
        ) : (
          <QuizButton />
        )}
      </Footer>
    </>
  );
};

export default QuizCompleteSentenceQuestion;
