import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from 'src/components/common/designSystem/Spinner';
import { useGenerateInvitationLink } from 'src/queries/invitations';
import { useNotification } from 'src/contexts/NotificationContext';
import Button from 'src/components/common/designSystem/Button';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';
import { useCurrentUser } from 'src/queries/user';
import { reportError } from 'src/modules/logs/Sentry';
import ShareLink from 'src/components/Invitation/ShareLink';

const ParentChildOtherDevice = ({
  childId,
  onFinish,
}: {
  childId: number;
  onFinish: () => void;
}) => {
  const { t } = useTranslation();

  const [otherDeviceLink, setOtherDeviceLink] = useState<string>();
  const { data: currentUser } = useCurrentUser();
  const {
    mutateAsync: generateInvitationLink,
    isLoading: generatingOtherDeviceLink,
  } = useGenerateInvitationLink();
  const { showError } = useNotification();

  const generateOtherDeviceLink = useCallback(async () => {
    if (otherDeviceLink || generatingOtherDeviceLink) {
      return;
    }
    try {
      await generateInvitationLink(childId, {
        onSuccess(generatedLink) {
          setOtherDeviceLink(generatedLink);
        },
      });
    } catch (error) {
      reportError('Fail to generate child invitation link', error);
      showError({
        message: t('onboarding.parent.child.device.share.error'),
        error,
      });
    }
  }, [
    childId,
    generateInvitationLink,
    generatingOtherDeviceLink,
    otherDeviceLink,
    showError,
    t,
  ]);

  useEffect(() => {
    generateOtherDeviceLink();
  }, [generateOtherDeviceLink]);

  const onShare = ({ activityType }: { activityType?: string }) => {
    logAnalyticsEvent('invite_user', {
      from_user: currentUser?.id,
      to_user: childId,
      activity_type: activityType,
    });
  };

  if (generatingOtherDeviceLink) {
    return <Spinner className="w-full h-full" size="large" />;
  }

  if (!otherDeviceLink) {
    return null;
  }

  return (
    <ShareLink
      data-testid={'other-device'}
      link={otherDeviceLink}
      onShare={onShare}
      locales={{
        title: t('invitations.modalShareTitle'),
        copied: {
          success: t('onboarding.parent.child.device.share.copied'),
        },
        share: {
          description: t('onboarding.parent.child.device.share.description'),
        },
      }}
    >
      <Button data-testid="btn-next" type="text" onClick={onFinish}>
        {t('global.end')}
      </Button>
    </ShareLink>
  );
};

export default ParentChildOtherDevice;
