import { Child } from 'src/types/user.types';
import DotsIcon from 'src/images/dots.svg?react';
import ShareIcon from 'src/images/share.svg?react';
import InfoIcon from 'src/images/info.svg?react';
import DeleteIcon from 'src/images/delete.svg?react';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import { useState } from 'react';
import InvitationChildPanel from 'src/components/Invitation/InvitationChildPanel';
import { useTranslation } from 'react-i18next';
import useDeleteChild from 'src/components/Parent/useDeleteChild';
import Spinner from 'src/components/common/designSystem/Spinner';
import EditChildFirstnamePanel from 'src/components/Parent/EditChildFirstnamePanel';

const ProfileChildCard = ({ child }: { child: Child }) => {
  const { t } = useTranslation();
  const [
    { isMainPanelOpen, isInvitePanelOpen, isFirstnamePanelOpen },
    setPanelOpening,
  ] = useState({
    isMainPanelOpen: false,
    isInvitePanelOpen: false,
    isFirstnamePanelOpen: false,
  });
  const { deleteChild, isLoading: isDeleting } = useDeleteChild({ child });

  const onOpenMainPanel = () => {
    setPanelOpening({
      isMainPanelOpen: true,
      isInvitePanelOpen: false,
      isFirstnamePanelOpen: false,
    });
  };
  const onClosePanels = () => {
    setPanelOpening({
      isMainPanelOpen: false,
      isInvitePanelOpen: false,
      isFirstnamePanelOpen: false,
    });
  };
  const onOpenInvitePanel = () => {
    setPanelOpening({
      isMainPanelOpen: false,
      isInvitePanelOpen: true,
      isFirstnamePanelOpen: false,
    });
  };
  const onDeleteChild = () => {
    onClosePanels();
    deleteChild();
  };
  const onOpenFirstnamePanel = () => {
    setPanelOpening({
      isMainPanelOpen: false,
      isInvitePanelOpen: false,
      isFirstnamePanelOpen: true,
    });
  };

  return (
    <>
      <div
        onClick={onOpenMainPanel}
        data-testid={`btn-child-${child.firstname}`}
        className={
          'flex flex-row justify-between items-center rounded-xl bg-white p-3 text-base'
        }
      >
        <div>
          {isDeleting ? <Spinner /> : null}
          <div>{`${child.firstname}`}</div>
        </div>
        <DotsIcon />
      </div>

      <AutoFloatingPanel
        data-testid={'panel-child'}
        isOpen={isMainPanelOpen}
        onClose={onClosePanels}
      >
        <div className={'flex flex-col py-4'}>
          <div className={'px-4 py-2 text-xl font-bold'}>{child.firstname}</div>
          <div
            onClick={onOpenInvitePanel}
            data-testid={`btn-invite-child-${child.firstname}`}
            className={
              'flex items-center justify-start gap-4 font-semibold px-4 py-3 text-base'
            }
          >
            <ShareIcon className={'fill-white w-6 h-auto'} />
            <span>{t('invitations.child.button')}</span>
          </div>
          <div
            onClick={onOpenFirstnamePanel}
            data-testid={`btn-edit-firstname`}
            className={
              'flex items-center justify-start gap-4 font-semibold px-4 py-3 text-base'
            }
          >
            <InfoIcon className={'fill-structural-darkest w-6 h-auto'} />
            <span>{t('profile.parent.children.firstname.button')}</span>
          </div>
          <div
            onClick={onDeleteChild}
            data-testid={`btn-delete-child`}
            className={
              'flex items-center justify-start gap-4 font-semibold px-4 py-3 text-base text-red-darkest'
            }
          >
            <DeleteIcon className={`fill-red-darkest w-6`} />
            <span>{t('profile.parent.children.delete.button')}</span>
          </div>
        </div>
      </AutoFloatingPanel>

      <InvitationChildPanel
        childId={child.id}
        isOpen={isInvitePanelOpen}
        onClose={onClosePanels}
      />
      <EditChildFirstnamePanel
        child={child}
        isOpen={isFirstnamePanelOpen}
        onClose={onClosePanels}
      />
    </>
  );
};

export default ProfileChildCard;
