import { QuestionStatus, useQuiz } from 'src/components/Quiz/QuizContext';
import CheckIcon from 'src/images/check-green.svg?react';
import FailIcon from 'src/images/fail.svg?react';
import QuizProgressBar from 'src/components/Quiz/QuizProgressBar';
import { QuestionType } from 'src/types/quiz.types';

const MAX_STATUS_SHOWN = 4;

const QuizStatus = () => {
  const {
    status,
    answersStatusHistory,
    totalQuestions,
    currentQuestionIndex,
    currentQuestion,
  } = useQuiz();

  if (
    status === QuestionStatus.WAITING ||
    status === QuestionStatus.WAITING_FOR_CORRECTION ||
    status === QuestionStatus.PAUSED
  ) {
    if (
      currentQuestion?.type === QuestionType.open ||
      currentQuestion?.type === QuestionType.korige_les_fotes
    ) {
      return null;
    }

    return (
      <div className={'flex justify-center items-center h-8 w-full'}>
        <QuizProgressBar />
      </div>
    );
  }

  const renderStatusHistory = () => {
    const statusItems = [];
    const startIndex = Math.min(
      totalQuestions - MAX_STATUS_SHOWN,
      Math.max(0, currentQuestionIndex - MAX_STATUS_SHOWN + 2),
    );
    const endIndex = Math.min(totalQuestions, startIndex + MAX_STATUS_SHOWN);

    for (let i = startIndex; i < endIndex; i++) {
      if (i > answersStatusHistory.length) {
        statusItems.push(
          <div
            key={i}
            className={'bg-black bg-opacity-20 p-0.5 rounded-full w-4 h-4'}
          />,
        );
        continue;
      }
      statusItems.push(
        <div
          key={i}
          className={'bg-black bg-opacity-20 p-0.5 rounded-full w-4 h-4'}
        >
          {answersStatusHistory[i] === QuestionStatus.ANSWERED_CORRECTLY ? (
            <CheckIcon className={'w-full h-full'} />
          ) : answersStatusHistory[i] ===
            QuestionStatus.ANSWERED_INCORRECTLY ? (
            <FailIcon className={'w-full h-full'} />
          ) : answersStatusHistory[i] === QuestionStatus.TIMED_OUT ? (
            <FailIcon className={'w-full h-full'} />
          ) : null}
        </div>,
      );
    }
    return statusItems;
  };

  return (
    <div className={'flex'}>
      <div
        className={
          'flex flex-row justify-center items-center gap-1 rounded-full bg-black bg-opacity-10 p-2 h-8'
        }
      >
        {renderStatusHistory()}
      </div>
    </div>
  );
};

export default QuizStatus;
