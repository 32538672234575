import { FC, ReactNode, type SVGProps } from 'react';
import { Link } from 'react-router-dom';
import LockIcon from 'src/images/lock.svg?react';

const LessonLink = ({
  link,
  title,
  IconComponent,
  'data-testid': dataTestId,
  disabled,
  disabledDescription,
}: {
  link: string;
  title: ReactNode;
  IconComponent: FC<SVGProps<SVGSVGElement>>;
  'data-testid'?: string;
  disabled?: boolean;
  disabledDescription?: string;
}) => {
  const Component = disabled ? 'div' : Link;
  return (
    <Component
      to={link}
      className={disabled ? 'select-none' : ''}
      aria-disabled={disabled}
      data-testid={dataTestId}
    >
      <div
        className={
          'p-4 bg-white w-full rounded-l flex flex-row gap-3 items-center'
        }
      >
        <div
          className={`w-10 h-10 flex items-center justify-center ${disabled ? 'opacity-30' : ''}`}
        >
          <IconComponent className={'w-auto h-auto max-h-full max-w-full'} />
        </div>
        <div className={'flex flex-col flex-grow'}>
          <div
            className={`font-bold text-base text-structural-darkest ${disabled ? 'opacity-30' : ''}`}
          >
            {title}
          </div>
          {disabled && disabledDescription ? (
            <div className={'text-sm font-semibold text-structural-darkest'}>
              {disabledDescription}
            </div>
          ) : null}
        </div>
        {disabled ? (
          <LockIcon />
        ) : (
          <div className={'text-2xl text-primary-darkest'}>{'>'}</div>
        )}
      </div>
    </Component>
  );
};

export default LessonLink;
