import QuizStatus from 'src/components/Quiz/QuizStatus';
import { KorigeLesFotesQuestion } from 'src/types/quiz.types';
import { Keyboard } from '@capacitor/keyboard';
import { useEffect, useState } from 'react';

const KorigeLesFotesQuizQuestionHeader = ({
  question,
}: {
  question: KorigeLesFotesQuestion;
}) => {
  const [keyboardIsVisible, setKeyboardIsVisible] = useState(false);

  useEffect(() => {
    Keyboard.addListener('keyboardDidShow', () => {
      setKeyboardIsVisible(true);
    });
    Keyboard.addListener('keyboardDidHide', () => {
      setKeyboardIsVisible(false);
    });
    return () => {
      Keyboard.removeAllListeners();
    };
  }, []);

  return (
    <div
      className={
        'px-8 py-2 w-full flex flex-1 flex-col gap-2 justify-between items-center overflow-hidden text-white'
      }
    >
      <div />
      <div
        className={`w-full flex text-center justify-center ${keyboardIsVisible ? 'text-lg' : 'text-2xl'} font-bold text-balance py-4 px-2 overflow-auto`}
      >
        {question.sentence_to_correct}
      </div>
      <QuizStatus />
    </div>
  );
};

export default KorigeLesFotesQuizQuestionHeader;
