import {
  CustomerInfo,
  LOG_LEVEL,
  Purchases,
  PurchasesEntitlementInfo,
} from '@revenuecat/purchases-capacitor';
import { Capacitor } from '@capacitor/core';
import { getAnalyticsDeviceId } from 'src/modules/analytics/Amplitude';
import { fakeSubscribe } from 'src/modules/purchase/purchase.fake';
import {
  ANNUAL_ENTITLEMENT_ID,
  ANNUAL_ENTITLEMENT_ID_WITHOUT_TRIAL,
  MONTHLY_ENTITLEMENT_ID,
  MONTHLY_ENTITLEMENT_ID_WITHOUT_TRIAL,
  RevenueCatOffering,
  SEMI_ANNUAL_ENTITLEMENT_ID,
} from 'src/modules/purchase/purchase.constants';
import { getModuleExternalId } from 'src/utils/external-id';
import { PromoCode } from 'src/types/promo_code.types';
import { AppLauncher } from '@capacitor/app-launcher';
import { App } from '@capacitor/app';

export const initInAppPurchase = async () => {
  if (Capacitor.getPlatform() === 'web') {
    await Purchases.setMockWebResults({ shouldMockWebResults: true });
  }

  await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG });
  if (Capacitor.getPlatform() === 'ios') {
    await Purchases.configure({
      apiKey: 'appl_gfNrmAOtFfJjCadVHLxaSfxjzOj',
    });
  }
  if (Capacitor.getPlatform() === 'android') {
    await Purchases.configure({
      apiKey: 'goog_QXvQYkNfgDgpdvAieZIVpYgbRhz',
    });
  }
};

export const subscribePremium = async (
  offering: RevenueCatOffering,
  promoCode?: PromoCode,
): Promise<{ customerInfo: CustomerInfo }> => {
  if (import.meta.env.VITE_CI) {
    return fakeSubscribe();
  }

  const { all } = await Purchases.getOfferings();
  if (!all || !all[offering]) {
    throw new Error('No offerings available');
  }
  if (promoCode) {
    if (Capacitor.getPlatform() === 'ios') {
      await AppLauncher.openUrl({
        url: `https://apps.apple.com/redeem?ctx=offercodes&id=6503043086&code=${promoCode.ios_promo_code}`,
      });
      return new Promise(resolve => {
        App.addListener('resume', async () => {
          await Purchases.syncPurchases();
          resolve(await Purchases.getCustomerInfo());
        });
      });
    } else if (Capacitor.getPlatform() === 'android') {
      const subscriptionOption = all[
        offering
      ].availablePackages[0].product.subscriptionOptions?.find(
        option => option.id === promoCode.android_offer_id,
      );
      if (!subscriptionOption) {
        throw new Error(
          'No subscription found for id ' + promoCode.android_offer_id,
        );
      }
      return Purchases.purchaseSubscriptionOption({
        subscriptionOption,
      });
    }
  }
  return Purchases.purchasePackage({
    aPackage: all[offering].availablePackages[0],
  });
};

export const logInAppPurchase = async (user: {
  id: number;
  family_id: number;
}): Promise<CustomerInfo | undefined> => {
  const { customerInfo } = await Purchases.logIn({
    appUserID: getModuleExternalId(user.family_id),
  });
  await Purchases.setAttributes({
    userID: user.id.toString(),
    familyID: user.family_id.toString(),
    $amplitudeDeviceId: getAnalyticsDeviceId() ?? '',
    $amplitudeUserId: user.id.toString(),
    $branchId: user.id.toString(),
    isFakeUser: Boolean(import.meta.env.VITE_CI).toString(),
    isStaging: (!Boolean(import.meta.env.PROD)).toString(),
    isProd: Boolean(import.meta.env.PROD).toString(),
  });
  await Purchases.invalidateCustomerInfoCache();

  return customerInfo;
};

export const logOutInAppPurchase = async () => {
  try {
    const { isAnonymous } = await Purchases.isAnonymous();
    if (isAnonymous) {
      return;
    }
    await Purchases.logOut();
  } catch (e) {}
};

export const getActiveSubscription = (
  customerInfo?: CustomerInfo,
): PurchasesEntitlementInfo | undefined => {
  if (!customerInfo) {
    return undefined;
  }
  if (customerInfo.entitlements.active[MONTHLY_ENTITLEMENT_ID]) {
    return customerInfo.entitlements.active[MONTHLY_ENTITLEMENT_ID];
  }
  if (customerInfo.entitlements.active[MONTHLY_ENTITLEMENT_ID_WITHOUT_TRIAL]) {
    return customerInfo.entitlements.active[
      MONTHLY_ENTITLEMENT_ID_WITHOUT_TRIAL
    ];
  }
  if (customerInfo.entitlements.active[ANNUAL_ENTITLEMENT_ID]) {
    return customerInfo.entitlements.active[ANNUAL_ENTITLEMENT_ID];
  }
  if (customerInfo.entitlements.active[ANNUAL_ENTITLEMENT_ID_WITHOUT_TRIAL]) {
    return customerInfo.entitlements.active[
      ANNUAL_ENTITLEMENT_ID_WITHOUT_TRIAL
    ];
  }
  if (customerInfo.entitlements.active[SEMI_ANNUAL_ENTITLEMENT_ID]) {
    return customerInfo.entitlements.active[SEMI_ANNUAL_ENTITLEMENT_ID];
  }
  return undefined;
};
