import { useState } from 'react';
import Star from 'src/components/common/StarReview/Star';

export type Rate = 0 | 1 | 2 | 3 | 4 | 5;
const rates: Rate[] = [1, 2, 3, 4, 5];

const StarReviewInput = ({
  value,
  onChange,
  isLoading,
  disabled,
  className,
}: {
  value: Rate;
  onChange?: (value: Rate) => void;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
}) => {
  const [rate, setRate] = useState(value);

  const onClick = (star: Rate) => () => {
    if (disabled) {
      return;
    }
    setRate(star);
    onChange?.(star);
  };

  return (
    <div
      className={`flex flex-row gap-4 ${className}`}
      data-testid={'inp-stars'}
    >
      {rates.map(star => (
        <Star
          key={star}
          style={{
            animationDelay: `${(star - 1) * 50}ms`,
          }}
          className={isLoading ? 'animate-bounce' : ''}
          isStarred={star <= rate}
          onClick={onClick(star)}
        />
      ))}
    </div>
  );
};

export default StarReviewInput;
