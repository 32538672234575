import { ReactNode } from 'react';

const PrimaryBox = ({
  children,
  'data-testid': dataTestId,
  className,
  containerClassName,
}: {
  children: ReactNode;
  'data-testid'?: string;
  className?: string;
  containerClassName?: string;
}) => {
  return (
    <div
      data-testid={dataTestId}
      className={`w-full items-center bg-gradient-to-r from-paywall-darkest-from to-paywall-darkest-to rounded-xl p-1 ${containerClassName}`}
    >
      <div
        className={`flex flex-row items-center justify-between gap-2 w-full h-full bg-white rounded-lg p-4 ${className}`}
      >
        {children}
      </div>
    </div>
  );
};

export default PrimaryBox;
