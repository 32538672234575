import { useEffect, useState } from 'react';
import {
  InAppNotification,
  InAppNotificationType,
} from 'src/types/notification.types';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import BaoHello from 'src/images/bao/bao-hello.svg?react';
import { useTranslation } from 'react-i18next';
import StarReviewInput, {
  Rate,
} from 'src/components/common/StarReview/StarReviewInput';
import { useAddAppReview, useUpdateAppReview } from 'src/queries/app_reviews';
import { useNotification } from 'src/contexts/NotificationContext';
import { AppReview } from 'src/types/app_review.types';
import Button from 'src/components/common/designSystem/Button';
import { InAppReview } from '@capacitor-community/in-app-review';
import ReviewComment from 'src/components/common/StarReview/ReviewComment';
import { reportError } from 'src/modules/logs/Sentry';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';
import { useCurrentUser } from 'src/queries/user';
import { UserType } from 'src/types/user.types';
import { Share } from '@capacitor/share';
import { isCancelError } from 'src/utils/share';

const InAppAskAppReviewNotifications = ({
  onRead,
}: {
  notification: InAppNotification & {
    type: InAppNotificationType.ASK_APP_REVIEW;
  };
  onRead: () => void;
}) => {
  const [isOpen, setIsOpened] = useState(true);
  const { t } = useTranslation();
  const { mutateAsync: addReview, isLoading: isLoadingAdd } = useAddAppReview();
  const { mutateAsync: updateReview, isLoading: isLoadingUpdate } =
    useUpdateAppReview();
  const { showError } = useNotification();
  const [review, setReview] = useState<AppReview>();
  const { data: user } = useCurrentUser();

  useEffect(() => {
    logAnalyticsEvent('card_review_viewed');
  }, []);

  const onReview = async ({
    rate,
    comment,
  }: {
    rate?: Rate;
    comment?: string;
  }) => {
    try {
      if (review) {
        setReview(await updateReview({ id: review.id, rate, comment }));
        return;
      }
      if (!rate) {
        return;
      }
      setReview(await addReview({ rate, comment }));
    } catch (error) {
      reportError('Fail to update app review', error);
      showError({
        error,
        message: t(`app_reviews.panel.${userType}.ask.error`),
      });
    }
  };

  const onClose = () => {
    setIsOpened(false);
    onRead();
  };

  const isLoading = isLoadingAdd || isLoadingUpdate;

  const userType = user?.user_type;

  if (!userType) {
    return null;
  }

  return (
    <AutoFloatingPanel
      isOpen={isOpen}
      onClose={onClose}
      data-testid={'panel-app-review'}
    >
      <div className={'flex items-start flex-col gap-5 p-6 text-left'}>
        {!review ? <AskReview userType={userType} /> : null}
        <StarReviewInput
          onChange={rate => {
            onReview({ rate });
            logAnalyticsEvent(`star-review-${rate}`);
          }}
          value={0}
          isLoading={isLoading}
        />
        {review ? (
          review.rate >= 4 ? (
            <PositiveReview userType={userType} onFinish={onClose} />
          ) : (
            <NegativeReview
              userType={userType}
              onComment={async (comment: string) => {
                await onReview({ comment });
                onClose();
              }}
              review={review}
              isLoading={isLoading}
            />
          )
        ) : null}
      </div>
    </AutoFloatingPanel>
  );
};

const AskReview = ({ userType }: { userType: UserType }) => {
  const { t } = useTranslation();

  return (
    <>
      <BaoHello className={'h-32 w-auto'} />
      <div className={'font-bold text-balance text-2xl'}>
        {t(`app_reviews.panel.${userType}.ask.title`)}
      </div>
      <div className={'flex flex-col gap-2 text-balance text-base'}>
        <div>{t(`app_reviews.panel.${userType}.ask.description1`)}</div>
        <div>{t(`app_reviews.panel.${userType}.ask.description2`)}</div>
      </div>
    </>
  );
};
const PositiveReview = ({
  onFinish,
  userType,
}: {
  onFinish: () => void;
  userType: UserType;
}) => {
  const { t } = useTranslation();

  const onClick = async () => {
    if (userType === UserType.parent) {
      try {
        const { activityType } = await Share.share({
          title: t(`app_reviews.panel.${userType}.positive.share.description`),
          text: t(`app_reviews.panel.${userType}.positive.share.description`),
          url: 'https://baobab.app.link/nCHU2oNvbPb',
        });
        await logAnalyticsEvent('button_share_app_parent_clicked', {
          activityType,
        });
      } catch (error) {
        if (isCancelError(error)) {
          return;
        }

        reportError('Fail to share app from parent review', error);
      }
    } else {
      await InAppReview.requestReview();
      await logAnalyticsEvent('button_store_review_clicked');
    }

    onFinish();
  };

  return (
    <>
      <div className={'font-bold text-balance text-2xl'}>
        {t(`app_reviews.panel.${userType}.positive.title`)}
      </div>
      <div className={'flex flex-col gap-2 text-balance text-base'}>
        <div>{t(`app_reviews.panel.${userType}.positive.description1`)}</div>
        <div>{t(`app_reviews.panel.${userType}.positive.description2`)}</div>
      </div>
      <Button type={'primary'} data-testid={'btn-review'} onClick={onClick}>
        {t(`app_reviews.panel.${userType}.positive.button`)}
      </Button>
    </>
  );
};
const NegativeReview = ({
  review,
  onComment,
  isLoading,
  userType,
}: {
  review: AppReview;
  onComment: (comment: string) => void;
  isLoading: boolean;
  userType: UserType;
}) => {
  const { t } = useTranslation();

  const comment = async (comment: string) => {
    onComment(comment);
    await logAnalyticsEvent('button_negative_review_clicked');
  };

  return (
    <>
      <div className={'font-bold text-balance text-2xl'}>
        {t(`app_reviews.panel.${userType}.negative.title`)}
      </div>
      <div className={'flex flex-col gap-2 text-balance text-base'}>
        {t(`app_reviews.panel.${userType}.negative.description`)}
      </div>
      <ReviewComment
        onComment={comment}
        comment={review.comment}
        isLoading={isLoading}
        userType={userType}
      />
    </>
  );
};

export default InAppAskAppReviewNotifications;
