export default {
  ok: 'OK',
  add: 'Ajouter',
  save: 'Sauvegarder',
  continue: 'Continuer',
  share: 'Partager',
  quit: 'Quitter',
  confirm: 'Confirmer',
  cancel: 'Annuler',
  end: 'Terminer',
  restart: 'Recommencer',
  next: 'Suivant',
  skip: 'Passer',
  notFoundError: "Cette page n'existe pas",
  goToHome: "Aller à l'accueil",
  or: 'ou',
  back: 'Retour',
  validation: {
    fieldRequired: 'Ce champ est obligatoire',
  },
  copyLink: 'Copier le lien',
  copied: 'Copié',
  letsGo: "C'est parti !",
  yes: 'Oui',
  no: 'Non',
  other: 'Autre',
  askLater: 'Me demander plus tard',
  showMore: 'Voir plus',
  showLess: 'Voir moins',
};
