import { useEffect, useRef, useState } from 'react';
import QuizWordPuzzleQuestionHeader from './QuizWordPuzzleQuestionHeader';
import QuizButton from 'src/components/Quiz/QuizButton';

import { useQuiz } from 'src/components/Quiz/QuizContext';
import QuizWordPuzzlePhrase from './QuizWordPuzzlePhrase';
import { WordPuzzleQuestion } from 'src/types/quiz.types';
import QuizWordPuzzleLetters from './QuizWordPuzzleLetters';
import { Haptics, NotificationType } from '@capacitor/haptics';
import QuizGuideline from 'src/components/Quiz/QuizGuideline';
import { SafeArea } from 'antd-mobile';

export type Letter = {
  letter: string;
  id: number;
};

const QuizWordPuzzleQuestion = ({
  question,
}: {
  question: WordPuzzleQuestion;
}) => {
  const { onAnswer } = useQuiz();
  const [letters, setLetters] = useState<Letter[]>([]);
  const emptyLettersIndexes = useRef<number[]>([]);

  const onClickSelectedLetter = (index: number) => {
    setLetters(letters => {
      const newLetters = [...letters];
      newLetters[index] = { letter: '', id: -1 };
      emptyLettersIndexes.current.push(index);
      emptyLettersIndexes.current.sort();
      return newLetters;
    });
  };

  const onAddLetter = (letter: Letter) => {
    setLetters(letters => {
      let newLetters = [...letters];
      const nbEmptyLetters = emptyLettersIndexes.current.length;
      if (nbEmptyLetters === 0) {
        if (question.correct[letters.length] === ' ') {
          newLetters = [
            ...newLetters,
            {
              letter: '',
              id: -1,
            },
          ];
        }
        newLetters = [...newLetters, letter];
      } else {
        newLetters[emptyLettersIndexes.current.shift()!] = letter;
      }
      return newLetters;
    });
  };

  useEffect(() => {
    if (
      letters.length === question.correct.length &&
      emptyLettersIndexes.current.length === 0
    ) {
      validateCompletions(letters);
    }
  }, [letters]);

  useEffect(() => {
    setLetters([]);
    emptyLettersIndexes.current = [];
  }, [question]);

  const validateCompletions = async (letters: Letter[]) => {
    const answer = letters.map(({ letter }) => letter).join('');
    const isCorrect = answer === question.correct.replace(/ /g, '');
    Haptics.notification({
      type: isCorrect ? NotificationType.Success : NotificationType.Error,
    });
    await onAnswer(answer, isCorrect);
  };

  return (
    <>
      <div
        className={
          'flex flex-col flex-1 justify-between items-start overflow-hidden'
        }
        data-testid={`question-${question.id}`}
      >
        <QuizWordPuzzleQuestionHeader question={question} />
        <div
          className={
            'flex flex-col w-full items-center gap-10 bg-white p-4 py-8 rounded-t-xl overflow-auto'
          }
        >
          <QuizGuideline />
          <QuizWordPuzzlePhrase
            question={question}
            selectedLetters={letters}
            onClickLetter={onClickSelectedLetter}
          />
          <QuizWordPuzzleLetters
            selectedLetters={letters}
            question={question}
            onClickLetter={onAddLetter}
          />
          <QuizButton />
          <SafeArea position="bottom" />
        </div>
      </div>
    </>
  );
};

export default QuizWordPuzzleQuestion;
