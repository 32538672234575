import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Share } from '@capacitor/share';
import { useNotification } from 'src/contexts/NotificationContext';
import { Clipboard } from '@capacitor/clipboard';
import { QRCode } from 'antd';
import Button from 'src/components/common/designSystem/Button';
import { isCancelError } from 'src/utils/share';
import { reportError } from 'src/modules/logs/Sentry';

const ShareLink = ({
  link,
  children,
  onShare,
  'data-testid': dataTestId,
  locales,
}: {
  link: string;
  children?: ReactNode;
  onShare?: ({ activityType }: { activityType?: string }) => void;
  'data-testid'?: string;
  locales: {
    title: string;
    copied?: {
      success?: string;
      button?: string;
    };
    share: {
      description: string;
      button?: string;
    };
  };
}) => {
  const { t } = useTranslation();

  const [canShare, setCanShare] = useState<boolean>(false);

  const checkCanShare = async () => {
    setCanShare((await Share.canShare()).value);
  };

  useEffect(() => {
    checkCanShare();
  }, []);

  const { showSuccess } = useNotification();

  const handleCopy = async () => {
    await Clipboard.write({
      string: link,
    });
    showSuccess({
      message: locales?.copied?.success || t('global.copied'),
    });
  };
  const handleShare = async () => {
    try {
      const { activityType } = await Share.share({
        title: locales.share.description,
        text: locales.share.description,
        url: link,
      });
      onShare?.({ activityType });
    } catch (error) {
      if (isCancelError(error)) {
        return;
      }

      reportError('Fail to share child invitation link', error);
    }
  };

  return (
    <div
      data-testid={dataTestId}
      className="flex-1 flex flex-col items-center justify-between gap-6 w-full h-full p-6"
    >
      <div className="text-center text-balance font-semibold text-base">
        {locales.title}
      </div>
      <QRCode bordered={false} size={96} value={link} status={'active'} />
      <div
        className="p-3 bg-white flex items-center gap-2 justify-between rounded-xl w-full"
        onClick={handleCopy}
      >
        <span className={'text-ellipsis whitespace-nowrap overflow-hidden'}>
          {link}
        </span>
        <div className={'w-5'}>
          <IconMdiContentCopy className="text-primary-darkest text-xl w-5" />
        </div>
      </div>
      <div className={'flex w-full flex-col gap-2'}>
        {canShare ? (
          <Button data-testid="btn-share" type="primary" onClick={handleShare}>
            {locales.share.button || t('global.share')}
          </Button>
        ) : (
          <Button data-testid="btn-share" type="primary" onClick={handleCopy}>
            {locales.copied?.button || t('global.copyLink')}
          </Button>
        )}
        {children}
      </div>
    </div>
  );
};

export default ShareLink;
