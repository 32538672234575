import QuizButton from 'src/components/Quiz/QuizButton';
import { KorigeLesFotesQuestion, QuizAnswer } from 'src/types/quiz.types';
import QuizHeader from 'src/components/Quiz/QuizHeader';
import QuizBackButton from 'src/components/Quiz/QuizBackButton';
import StarReviewInput, {
  Rate,
} from 'src/components/common/StarReview/StarReviewInput';
import { useTranslation } from 'react-i18next';
import BaoWink from 'src/images/bao/bao-wink.svg?react';
import BaoNotBad from 'src/images/bao/bao-not-bad.svg?react';
import BaoSad from 'src/images/bao/bao-sad.svg?react';
import PrimaryBox from 'src/components/common/PrimaryBox';
import BlankWhiteLayout from 'src/layouts/BlankWhiteLayout';
import Footer from 'src/components/common/designSystem/Footer';
import ShowMoreText from 'src/components/common/ShowMoreText';

const KorigeLesFotesQuizQuestionAnswer = ({
  question,
  answer,
}: {
  question: KorigeLesFotesQuestion;
  answer: QuizAnswer;
}) => {
  const { t } = useTranslation();

  const getTitle = () => {
    if (!answer.score) {
      return t('quiz.openQuestion.noScore');
    }
    if (answer.score >= 4) {
      return t('quiz.openQuestion.good');
    } else if (answer.score >= 2) {
      return t('quiz.openQuestion.notBad');
    } else {
      return t('quiz.openQuestion.bad');
    }
  };

  const getBao = () => {
    if (!answer.score) {
      return <BaoWink className={'w-auto h-36'} />;
    }
    if (answer.score >= 4) {
      return <BaoWink className={'w-auto h-36'} />;
    } else if (answer.score >= 2) {
      return <BaoNotBad className={'w-auto h-36'} />;
    } else {
      return <BaoSad className={'w-auto h-36'} />;
    }
  };

  return (
    <BlankWhiteLayout>
      <div
        className={
          'flex flex-col h-full flex-1 justify-between items-start overflow-hidden'
        }
        data-testid={`question-${question.id}`}
      >
        <QuizHeader
          back={<QuizBackButton color={'structural-darkest'} />}
          color={'structural-darkest'}
        />

        <div
          className={
            'flex flex-col w-full items-center gap-6 bg-white p-6 rounded-t-xl overflow-auto'
          }
        >
          <div className={'flex items-center'}>{getBao()}</div>
          <div className={'text-3xl font-bold'}>{getTitle()}</div>

          {answer.explanation ? (
            <div className={'text-base'}>
              <ShowMoreText text={answer.explanation} />
            </div>
          ) : null}

          <PrimaryBox
            className={'flex flex-row items-center justify-between gap-2'}
          >
            <div className={'font-bold uppercase'}>
              {t('quiz.openQuestion.score')}
            </div>
            <div className={'w-32'}>
              <StarReviewInput
                className={'!gap-1'}
                value={(answer.score ?? 1) as Rate}
                disabled
              />
            </div>
          </PrimaryBox>
        </div>

        <Footer>
          <div className={'w-full px-4 py-3'}>
            <QuizButton />
          </div>
        </Footer>
      </div>
    </BlankWhiteLayout>
  );
};

export default KorigeLesFotesQuizQuestionAnswer;
