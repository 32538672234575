import { useTranslation } from 'react-i18next';
import { QuestionStatus, useQuiz } from 'src/components/Quiz/QuizContext';
import { QuestionType } from 'src/types/quiz.types';

const QuizGuideline = () => {
  const { t } = useTranslation();
  const { status, currentQuestion } = useQuiz();

  if (!currentQuestion) {
    return null;
  }

  const commonClasses = 'text-base font-semibold text-structural-darker';

  switch (status) {
    case QuestionStatus.ANSWERED_CORRECTLY:
      return <div className={commonClasses}>{t('quiz.goodAnswer')}</div>;
    case QuestionStatus.ANSWERED_INCORRECTLY:
      return <div className={commonClasses}>{t('quiz.wrongAnswer')}</div>;
    case QuestionStatus.TIMED_OUT:
      return <div className={commonClasses}>{t('quiz.timeoutAnswer')}</div>;
    case QuestionStatus.WAITING:
    case QuestionStatus.PAUSED:
      if (currentQuestion.type === QuestionType.open) {
        return null;
      }
      if (currentQuestion.type === QuestionType.korige_les_fotes) {
        return (
          <div className={commonClasses}>
            {t('quiz.korige_les_fotes.guideline')}
          </div>
        );
      }
      if (currentQuestion.type === QuestionType.word_puzzle) {
        return (
          <div className={commonClasses}>{t('quiz.defaultGuideline')}</div>
        );
      }
      return (
        <div className={commonClasses}>
          {currentQuestion.guideline.length
            ? currentQuestion.guideline
            : t('quiz.defaultGuideline')}
        </div>
      );
  }
};

export default QuizGuideline;
