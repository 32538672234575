import StarIcon from 'src/images/star.svg?react';
import { CSSProperties, useLayoutEffect, useState } from 'react';

const Star = ({
  isStarred,
  onClick,
  style,
  className = '',
}: {
  isStarred: boolean;
  onClick?: () => void;
  style?: CSSProperties;
  className?: string;
}) => {
  const [, setEffect] = useState(false);

  useLayoutEffect(() => {
    setEffect(isStarred);
  }, [isStarred]);

  return (
    <StarIcon
      onClick={onClick}
      className={`w-10 h-auto cursor-pointer ${
        isStarred ? `fill-[#FFE100]` : 'fill-white'
      } ${className}`}
      style={style}
    />
  );
};

export default Star;
