import { useState } from 'react';
import Button from 'src/components/common/designSystem/Button';
import { useTranslation } from 'react-i18next';
import BaoSpeak from 'src/images/bao/bao-speak.svg?react';
import SpeakBubble from 'src/images/speak-bubble.svg?react';
import QuizHeader from 'src/components/Quiz/QuizHeader';
import QuizBackButton from 'src/components/Quiz/QuizBackButton';
import BlankPrimaryLayout from 'src/layouts/BlankPrimaryLayout';

const BaoQuizOnboarding = ({ onEnd }: { onEnd: () => void }) => {
  const [step, setStep] = useState(0);
  const { t } = useTranslation();

  const handleNext = () => {
    if (step === 2) {
      onEnd();
      return;
    }
    setStep(step + 1);
  };

  return (
    <BlankPrimaryLayout>
      <div
        className={
          'w-full flex-1 flex flex-col justify-between overflow-hidden'
        }
      >
        <QuizHeader back={<QuizBackButton />} />

        <div className={'flex flex-col justify-between p-4'}>
          <div className={'flex flex-col justify-center items-end'}>
            <div
              className={
                'relative w-[321px] h-[228px] flex items-center justify-center pb-20 px-8'
              }
            >
              <SpeakBubble className={'absolute top-0'} />
              <div className={'font-bold text-2xl relative'}>
                {t(`quiz.bao.onboarding.${step}.message`)}
              </div>
            </div>
            <BaoSpeak className={'h-72 -mt-16'} />
          </div>
          <Button
            data-testid={'btn-onboarding-bao-quiz'}
            type={'primary'}
            onClick={handleNext}
          >
            {t(`quiz.bao.onboarding.${step}.button`)}
          </Button>
        </div>
      </div>
    </BlankPrimaryLayout>
  );
};

export default BaoQuizOnboarding;
