import { FormEvent, useEffect, useState } from 'react';
import KorigeLesFotesQuizQuestionHeader from './KorigeLesFotesQuizQuestionHeader';
import QuizButton from 'src/components/Quiz/QuizButton';
import { QuestionStatus, useQuiz } from 'src/components/Quiz/QuizContext';
import QuizGuideline from 'src/components/Quiz/QuizGuideline';
import { Haptics, NotificationType } from '@capacitor/haptics';
import { KorigeLesFotesQuestion, QuizAnswer } from 'src/types/quiz.types';
import Button from 'src/components/common/designSystem/Button';
import { useTranslation } from 'react-i18next';
import { useNotification } from 'src/contexts/NotificationContext';
import { reportError } from 'src/modules/logs/Sentry';
import KorigeLesFotesQuizQuestionAnswer from './KorigeLesFotesQuizQuestionAnswer';
import Spinner from 'src/components/common/designSystem/Spinner';
import Textarea from 'src/components/common/designSystem/Textarea';
import QuizLayout from 'src/components/Quiz/QuizLayout';

const KorigeLesFotesQuizQuestion = ({
  question,
}: {
  question: KorigeLesFotesQuestion;
}) => {
  const { onAnswer, status } = useQuiz();
  const [answer, setAnswer] = useState<string>('');
  const [answerApi, setAnswerApi] = useState<QuizAnswer>();
  const { t } = useTranslation();
  const { showError } = useNotification();

  const validateAnswer = async () => {
    try {
      const apiAnswer = await onAnswer(answer);
      setAnswerApi(apiAnswer);

      Haptics.notification({
        type: apiAnswer.is_correct
          ? NotificationType.Success
          : NotificationType.Error,
      });
    } catch (error) {
      reportError('Fail to send answer', error);
      showError({
        error,
        message: t('error.http.default'),
      });
    }
  };

  useEffect(() => {
    setAnswer('');
  }, [question]);

  if (
    status === QuestionStatus.ANSWERED_CORRECTLY ||
    status === QuestionStatus.ANSWERED_INCORRECTLY
  ) {
    if (answerApi) {
      return (
        <KorigeLesFotesQuizQuestionAnswer
          question={question}
          answer={answerApi}
        />
      );
    }

    return (
      <QuizLayout>
        <Spinner size={'large'} />
      </QuizLayout>
    );
  }

  return (
    <QuizLayout>
      <div
        className={
          'flex flex-col flex-1 justify-between items-start overflow-hidden'
        }
        data-testid={`question-${question.id}`}
      >
        <KorigeLesFotesQuizQuestionHeader question={question} />
        <div
          className={
            'flex flex-col w-full items-center gap-6 bg-white p-6 rounded-t-xl'
          }
        >
          <QuizGuideline />
          <div className={'flex flex-col w-full gap-2 text-structural-darker'}>
            <Textarea
              data-testid="inp-answer"
              value={answer}
              onChange={(e: FormEvent<HTMLTextAreaElement>) => {
                setAnswer(e.currentTarget.value);
              }}
              disabled={status === QuestionStatus.WAITING_FOR_CORRECTION}
            />
          </div>
          {status === QuestionStatus.WAITING ||
          status === QuestionStatus.WAITING_FOR_CORRECTION ? (
            <Button
              type={'primary'}
              data-testid={'btn-validate-answer'}
              onClick={validateAnswer}
              loading={status === QuestionStatus.WAITING_FOR_CORRECTION}
            >
              {t('global.next')}
            </Button>
          ) : (
            <QuizButton />
          )}
        </div>
      </div>
    </QuizLayout>
  );
};

export default KorigeLesFotesQuizQuestion;
