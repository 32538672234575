import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ReadMoreProps {
  text: string;
  amountOfWords?: number;
}

const ShowMoreText = ({ text, amountOfWords = 36 }: ReadMoreProps) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const splittedText = text.split(' ');
  const itCanOverflow = splittedText.length > amountOfWords;
  const beginText = itCanOverflow
    ? splittedText.slice(0, amountOfWords - 1).join(' ')
    : text;
  const endText = splittedText.slice(amountOfWords - 1).join(' ');

  return (
    <div>
      {beginText}
      {itCanOverflow && (
        <>
          {!isExpanded && <span>... </span>}
          <span
            className={`${!isExpanded && 'hidden'}`}
            aria-hidden={!isExpanded}
          >
            {endText}
          </span>
          <span
            className="text-primary-darkest ml-2"
            role="button"
            tabIndex={0}
            aria-expanded={isExpanded}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {t(isExpanded ? 'global.showLess' : 'global.showMore')}
          </span>
        </>
      )}
    </div>
  );
};

export default ShowMoreText;
