export default {
  panel: {
    parent: {
      ask: {
        title: 'Votre avis est super important !',
        description1:
          'Votre enfant a commencé à utiliser l’application pour réviser !',
        description2: 'Vous donneriez quelle note à Baobab ?',
        error: 'Une erreur est survenue, veuillez réessayer.',
      },
      positive: {
        title: 'Merci pour votre avis !',
        description1:
          'N’hésitez pas à partager l’application auprès d’autres parents, groupes d’amis etc.',
        description2: 'Cela nous aide énormément !! 🤩🙏',
        button: 'Partager l’application',
        share: {
          description:
            'On utilise Baobab pour les révisions, c’est super ! A essayer : ',
        },
      },
      negative: {
        title: 'Merci pour ton avis !',
        description: 'Comment nous pouvons nous améliorer ?',
        placeholder: 'J’aimerai que ...',
        button: 'Laisser un avis',
      },
    },
    child: {
      ask: {
        title: 'Ton avis est super important !',
        description1: 'Tu as déjà fait plein de quiz, c’est incroyable !',
        description2: 'Tu donnes quelle note à Baobab ?',
        error: 'Une erreur est survenue, veuillez réessayer.',
      },
      positive: {
        title: 'Merci pour ton avis !',
        description1:
          'Aide d’autres enfants à découvrir Baobab en laissant un avis !',
        description2: 'Cela nous aide énormément !! 🤩🙏',
        button: 'Laisser un avis',
      },
      negative: {
        title: 'Merci pour ton avis !',
        description: 'Comment nous pouvons nous améliorer ?',
        placeholder: 'Dis-nous tout !',
        button: 'Laisser un avis',
      },
    },
  },
};
