export enum RevenueCatOffering {
  MONTHLY = 'default',
  MONTHLY_WITHOUT_TRIAL = 'without_trial',
  SEMI_ANNUAL = 'premium_semi_annual',
  ANNUAL = 'premium_annual',
  ANNUAL_WITHOUT_TRIAL = 'premium_annual_without_trial',
}

export const MONTHLY_ENTITLEMENT_ID = 'premium';
export const MONTHLY_ENTITLEMENT_ID_WITHOUT_TRIAL = 'premium_without_trial';
export const SEMI_ANNUAL_ENTITLEMENT_ID = 'premium_semi_annual';
export const ANNUAL_ENTITLEMENT_ID = 'premium_annual';
export const ANNUAL_ENTITLEMENT_ID_WITHOUT_TRIAL =
  'premium_annual_without_trial';

export const ANNUAL_OFFERS = [
  RevenueCatOffering.ANNUAL,
  RevenueCatOffering.ANNUAL_WITHOUT_TRIAL,
];
export const SEMI_ANNUAL_OFFERS = [RevenueCatOffering.SEMI_ANNUAL];
export const MONTHLY_OFFERS = [
  RevenueCatOffering.MONTHLY,
  RevenueCatOffering.MONTHLY_WITHOUT_TRIAL,
];

export const MONTHLY_ENTITLEMENT_IDS = [
  MONTHLY_ENTITLEMENT_ID,
  MONTHLY_ENTITLEMENT_ID_WITHOUT_TRIAL,
];
export const SEMI_ANNUAL_ENTITLEMENT_IDS = [SEMI_ANNUAL_ENTITLEMENT_ID];
export const ANNUAL_ENTITLEMENT_IDS = [
  ANNUAL_ENTITLEMENT_ID,
  ANNUAL_ENTITLEMENT_ID_WITHOUT_TRIAL,
];
