import {
  ANNUAL_OFFERS,
  MONTHLY_OFFERS,
  RevenueCatOffering,
  SEMI_ANNUAL_OFFERS,
} from 'src/modules/purchase/purchase.constants';
import AnnualOffer from 'src/components/Paywall/Offers/AnnualOffer';
import MonthlyOffer from 'src/components/Paywall/Offers/MonthlyOffer';
import { useTranslation } from 'react-i18next';
import SemiAnnualOffer from 'src/components/Paywall/Offers/SemiAnnualOffer';

const OfferSelector = ({
  onSelectMonthlyOffer,
  onSelectSemiAnnualOffer,
  onSelectAnnualOffer,
  selectedOffer,
}: {
  selectedOffer: RevenueCatOffering;
  onSelectMonthlyOffer?: () => void;
  onSelectSemiAnnualOffer?: () => void;
  onSelectAnnualOffer?: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className={'flex flex-col gap-2 w-full'}>
      {onSelectAnnualOffer ? (
        <div
          className={'cursor-pointer relative'}
          onClick={onSelectAnnualOffer}
        >
          <AnnualOffer
            isSelected={ANNUAL_OFFERS.includes(selectedOffer)}
            promo={t('paywall.panel.annual.threeMonthsFree')}
          />
        </div>
      ) : null}
      {onSelectSemiAnnualOffer ? (
        <div
          className={'cursor-pointer relative'}
          onClick={onSelectSemiAnnualOffer}
        >
          <SemiAnnualOffer
            isSelected={SEMI_ANNUAL_OFFERS.includes(selectedOffer)}
          />
        </div>
      ) : null}
      {onSelectMonthlyOffer ? (
        <div
          className={'cursor-pointer relative'}
          onClick={onSelectMonthlyOffer}
        >
          <MonthlyOffer isSelected={MONTHLY_OFFERS.includes(selectedOffer)} />
        </div>
      ) : null}
    </div>
  );
};

export default OfferSelector;
