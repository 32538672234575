import { ReactNode } from 'react';
import QuizHeaderMenu from 'src/components/Quiz/QuizHeaderMenu';
import { useQuiz } from 'src/components/Quiz/QuizContext';

const QuizHeader = ({
  back,
  color = 'white',
}: {
  back?: ReactNode;
  color?: string;
}) => {
  const { lesson, currentQuestionIndex, totalQuestions } = useQuiz();
  return (
    <>
      <div className={'w-full px-4 pt-4'}>
        <div
          className={
            'flex flex-row h-16 justify-between items-center gap-4 w-full'
          }
        >
          {back ?? <div className={'w-16'} />}
          <div
            className={`flex flex-col justify-center items-center flex-shrink overflow-hidden text-${color}`}
          >
            <div
              className={
                'text-base font-semibold overflow-ellipsis whitespace-nowrap overflow-hidden w-full'
              }
            >
              {lesson?.title}
            </div>
            <div className={'font-semibold'}>
              {currentQuestionIndex + 1}/{totalQuestions}
            </div>
          </div>
          <QuizHeaderMenu color={color} />
        </div>
      </div>
    </>
  );
};

export default QuizHeader;
