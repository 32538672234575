import QuizMCQQuestion from 'src/components/Quiz/MCQQuestion/QuizMCQQuestion';
import { useQuiz } from 'src/components/Quiz/QuizContext';
import QuizMatchingQuestion from 'src/components/Quiz/MatchingQuestion/QuizMatchingQuestion';
import QuizFillBlankQuestion from 'src/components/Quiz/FillBlankQuestion/QuizFillBlankQuestion';
import QuizCompleteSentenceQuestion from 'src/components/Quiz/CompleteSentenceQuestion/QuizCompleteSentenceQuestion';
import { QuestionType, QuizType } from 'src/types/quiz.types';
import QuizWordPuzzleQuestion from 'src/components/Quiz/WordPuzzleQuestion/QuizWordPuzzleQuestion';
import OpenQuizQuestion from 'src/components/Quiz/OpenQuestion/OpenQuizQuestion';
import { useState } from 'react';
import BaoQuizOnboarding from 'src/components/Quiz/BaoQuizOnboarding';
import QuizLayout from 'src/components/Quiz/QuizLayout';
import KorigeLesFotesQuizQuestion from 'src/components/Quiz/KorigeLesFotesQuestion/KorigeLesFotesQuizQuestion';
import KorigeLesFotesQuizOnboarding from 'src/components/Quiz/KorigeLesFotesQuizOnboarding';

const Quiz = () => {
  const { currentQuestion, quizType } = useQuiz();
  const [hasDoneOnboarding, setHasDoneOnboarding] = useState(false);

  if (!currentQuestion) {
    return null;
  }

  if (quizType === QuizType.BAO && !hasDoneOnboarding) {
    return <BaoQuizOnboarding onEnd={() => setHasDoneOnboarding(true)} />;
  }

  if (quizType === QuizType.KORIGE_LES_FOTES && !hasDoneOnboarding) {
    return (
      <KorigeLesFotesQuizOnboarding onEnd={() => setHasDoneOnboarding(true)} />
    );
  }

  if (currentQuestion.type === QuestionType.matching) {
    return (
      <QuizLayout>
        <QuizMatchingQuestion question={currentQuestion} />
      </QuizLayout>
    );
  }
  if (currentQuestion.type === QuestionType.mcq) {
    return (
      <QuizLayout>
        <QuizMCQQuestion question={currentQuestion} />
      </QuizLayout>
    );
  }
  if (currentQuestion.type === QuestionType.fill_blank) {
    return (
      <QuizLayout>
        {' '}
        <QuizFillBlankQuestion question={currentQuestion} />
      </QuizLayout>
    );
  }
  if (currentQuestion.type === QuestionType.complete_sentence) {
    return (
      <QuizLayout>
        <QuizCompleteSentenceQuestion question={currentQuestion} />
      </QuizLayout>
    );
  }
  if (currentQuestion.type === QuestionType.word_puzzle) {
    return (
      <QuizLayout>
        <QuizWordPuzzleQuestion question={currentQuestion} />
      </QuizLayout>
    );
  }
  if (currentQuestion.type === QuestionType.open) {
    return <OpenQuizQuestion question={currentQuestion} />;
  }
  if (currentQuestion.type === QuestionType.korige_les_fotes) {
    return <KorigeLesFotesQuizQuestion question={currentQuestion} />;
  }
  return null;
};

export default Quiz;
