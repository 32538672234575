import { usePaywall } from 'src/contexts/PaywallContext';
import {
  MONTHLY_ENTITLEMENT_IDS,
  SEMI_ANNUAL_ENTITLEMENT_IDS,
} from 'src/modules/purchase/purchase.constants';
import MonthlyOffer from 'src/components/Paywall/Offers/MonthlyOffer';
import SemiAnnualOffer from 'src/components/Paywall/Offers/SemiAnnualOffer';
import AnnualOffer from 'src/components/Paywall/Offers/AnnualOffer';

const MyOffer = () => {
  const { activeSubscription, managementURL } = usePaywall();

  const isMonthly = MONTHLY_ENTITLEMENT_IDS.includes(
    activeSubscription?.identifier,
  );
  if (isMonthly) {
    return <MonthlyOffer />;
  }

  const isSemiAnnual =
    activeSubscription?.identifier === SEMI_ANNUAL_ENTITLEMENT_IDS;
  if (isSemiAnnual) {
    return <SemiAnnualOffer />;
  }

  return <AnnualOffer />;
};

export default MyOffer;
