import { useEffect, useMemo, useState } from 'react';
import { shuffle } from 'src/utils/helper-functions';
import QuizAnswer from 'src/components/Quiz/QuizAnswer';
import QuizMCQQuestionHeader from './QuizMCQQuestionHeader';
import QuizButton from 'src/components/Quiz/QuizButton';
import { QuestionStatus, useQuiz } from 'src/components/Quiz/QuizContext';
import QuizGuideline from 'src/components/Quiz/QuizGuideline';
import { Haptics, NotificationType } from '@capacitor/haptics';
import { MCQQuestion } from 'src/types/quiz.types';

const MAX_ANSWERS = 3;

const QuizMCQQuestion = ({ question }: { question: MCQQuestion }) => {
  const { onAnswer, status } = useQuiz();
  const [selectedAnswer, setSelectedAnswer] = useState<string>();
  const shuffledAnswers = useMemo(() => {
    const incorrectAnswers = question.incorrect.slice(0, MAX_ANSWERS - 1);
    return shuffle([...question.correct, ...incorrectAnswers]);
  }, [question.correct, question.incorrect]);

  const validateAnswer = async (answer: string) => {
    const isCorrect = question.correct.includes(answer);

    Haptics.notification({
      type: isCorrect ? NotificationType.Success : NotificationType.Error,
    });

    setSelectedAnswer(answer);
    await onAnswer(answer, isCorrect);
  };

  useEffect(() => {
    setSelectedAnswer(undefined);
  }, [question]);

  return (
    <>
      <div
        className={
          'flex flex-col flex-1 justify-between items-start overflow-hidden'
        }
        data-testid={`question-${question.id}`}
      >
        <QuizMCQQuestionHeader question={question} />
        <div
          className={
            'flex flex-col w-full items-center gap-6 bg-white p-6 rounded-t-xl'
          }
        >
          <QuizGuideline />
          <div className={'flex flex-col w-full gap-2 text-structural-darker'}>
            {shuffledAnswers.map((answer, index) => (
              <QuizAnswer
                key={index}
                id={answer}
                showCorrection={
                  status !== QuestionStatus.WAITING &&
                  status !== QuestionStatus.PAUSED
                }
                isCorrect={question.correct.includes(answer)}
                isSelected={selectedAnswer === answer}
                onClick={
                  status === QuestionStatus.WAITING
                    ? () => validateAnswer(answer)
                    : undefined
                }
              />
            ))}
          </div>
          <QuizButton />
        </div>
      </div>
    </>
  );
};

export default QuizMCQQuestion;
