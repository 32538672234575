import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { initFeatureFlags } from 'src/modules/feature-flags';
import { useCurrentUser } from 'src/queries/user';
import { ExperimentClient, Variants } from '@amplitude/experiment-js-client';

import { User } from 'src/types/user.types';
import { reportError } from 'src/modules/logs/Sentry';
import { getModuleExternalId } from 'src/utils/external-id';
import { FlagsType, PaywallScreen2Parent } from 'src/types/flags.types';

const FlagsContext = createContext<FlagsType | null>(null);

const getPaywallScreen2Parent = (value: string): PaywallScreen2Parent => {
  if (value === 'paywall_parent_6_months') {
    return PaywallScreen2Parent.paywall_parent_6_months;
  }
  if (value === 'paywall_parent_v2') {
    return PaywallScreen2Parent.paywall_parent_v2;
  }
  return PaywallScreen2Parent.paywall_parent_v1;
};
const getFlags = async (variants: Variants): Promise<FlagsType> => {
  // @ts-ignore
  if (window.getFakeFlags) {
    // @ts-ignore
    return await window.getFakeFlags();
  }
  return {
    child_trial_store_paywall:
      variants['child_trial_store_paywall']?.value === 'true',
    display_video_paywall: variants['display_video_paywall']?.value === 'true',
    paywall_screen2_parent: getPaywallScreen2Parent(
      variants['paywall_screen2_parent']?.value || '',
    ),
  };
};

const FlagsProvider = ({
  defaults,
  children,
}: PropsWithChildren<{
  defaults: FlagsType;
}>) => {
  const [flags, setFlags] = useState<FlagsType>(defaults);
  const { data: user } = useCurrentUser();
  const amplitudeExperiment = useRef<ExperimentClient>();

  const refresh = useCallback(async (user?: User) => {
    if (amplitudeExperiment.current) {
      if (!user?.id) {
        amplitudeExperiment.current = await amplitudeExperiment.current.fetch();
      } else {
        amplitudeExperiment.current = await amplitudeExperiment.current.fetch({
          user_id: getModuleExternalId(user.id),
          user_properties: {
            user_type: user.user_type,
            family_id: String(user.family_id),
          },
        });
      }
    }
  }, []);

  const initialize = useCallback(
    async (user?: User) => {
      try {
        if (amplitudeExperiment.current) {
          await refresh(user);
        } else {
          amplitudeExperiment.current = initFeatureFlags();
          if (!amplitudeExperiment.current) {
            // @ts-ignore
            if (window.getFakeFlags) {
              // @ts-ignore
              setFlags(await window.getFakeFlags());
            }
            return;
          }
          await amplitudeExperiment.current.start(
            user?.id
              ? {
                  user_id: getModuleExternalId(user.id),
                  user_properties: {
                    user_type: user.user_type,
                    family_id: String(user.family_id),
                  },
                }
              : undefined,
          );
        }

        const newFlags = await getFlags(amplitudeExperiment.current.all());

        setFlags(flags => {
          if (!amplitudeExperiment.current) {
            return flags;
          }
          return {
            ...flags,
            ...newFlags,
          };
        });
      } catch (error) {
        reportError('Error initializing amplitude feature flags', error);
      }
    },
    [refresh],
  );

  useEffect(() => {
    initialize(user);
  }, [initialize, user]);

  return (
    <FlagsContext.Provider value={flags}>{children}</FlagsContext.Provider>
  );
};

export const useFlags = () => {
  const flags = useContext(FlagsContext);
  if (!flags) {
    throw new Error('useFlags must be used within a FlagsContext');
  }
  return flags;
};

export default FlagsProvider;
