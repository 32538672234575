import QuizHeader from 'src/components/Quiz/QuizHeader';
import QuizBackButton from 'src/components/Quiz/QuizBackButton';
import BlankPrimaryLayout from 'src/layouts/BlankPrimaryLayout';
import { ReactNode } from 'react';

const QuizLayout = ({ children }: { children: ReactNode }) => {
  return (
    <BlankPrimaryLayout appBackgroundFooter={'bg-white'}>
      <div
        className={
          'w-full flex-1 flex flex-col justify-between overflow-hidden'
        }
      >
        <QuizHeader back={<QuizBackButton />} />
        {children}
      </div>
    </BlankPrimaryLayout>
  );
};

export default QuizLayout;
