import { Link, useSearchParams } from 'react-router-dom';
import LogoMonkey from 'src/images/logo-monkey.svg?react';
import LaurierLeftIcon from 'src/images/laurier-left.svg?react';
import LaurierRightIcon from 'src/images/laurier-right.svg?react';
import StoreStarsIcon from 'src/images/store-stars.svg?react';
import Button from 'src/components/common/designSystem/Button';
import { Trans, useTranslation } from 'react-i18next';
import BlankPrimaryLayout from 'src/layouts/BlankPrimaryLayout';

function Home() {
  const { t } = useTranslation();
  const [searchParams, _] = useSearchParams();

  return (
    <BlankPrimaryLayout>
      <div
        className={
          'flex flex-col items-center justify-between w-full h-full py-6'
        }
      >
        <LogoMonkey className="h-40 mt-6" />
        <div className={'flex flex-row gap-1'}>
          <LaurierLeftIcon />
          <div className={'flex flex-col gap-2 justify-center items-center'}>
            <div className={'text-3xl font-extrabold'}>4,8</div>
            <StoreStarsIcon />
            <div className={'text-base font-bold'}>+ de 180 avis</div>
          </div>
          <LaurierRightIcon />
        </div>
        <div className={'flex flex-col items-center w-full gap-3 p-6'}>
          <Link
            to={{
              pathname: '/login',
              search: searchParams.toString(),
            }}
            className="w-full"
          >
            <Button
              data-testid="btn-letsgo"
              type={'primary'}
              className="w-full"
            >
              {t('global.letsGo')}
            </Button>
          </Link>
          <div className={'text-center text-sm text-white text-balance w-full'}>
            <Trans i18nKey={'landing.footer'}>
              En continuant, j’accepte les{' '}
              <Link
                to={'/terms-and-conditions'}
                className={'font-extrabold text-white'}
              >
                conditions générales d’utilisation
              </Link>{' '}
              et{' '}
              <Link
                to={'/confidentialite'}
                className={'font-extrabold text-white'}
              >
                politiques de confidentialité
              </Link>
            </Trans>
          </div>
        </div>
      </div>
    </BlankPrimaryLayout>
  );
}
export default Home;
