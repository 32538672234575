import { useTranslation } from 'react-i18next';
import Button from 'src/components/common/designSystem/Button';
import { QuestionStatus, useQuiz } from 'src/components/Quiz/QuizContext';

const QuizButton = () => {
  const { t } = useTranslation();
  const { currentQuestionIndex, status, totalQuestions, nextQuestion } =
    useQuiz();

  return (
    <Button
      type="primary"
      data-testid="btn-next-question"
      disabled={
        status === QuestionStatus.WAITING ||
        status === QuestionStatus.WAITING_FOR_CORRECTION
      }
      onClick={nextQuestion}
    >
      {currentQuestionIndex < totalQuestions - 1
        ? t('quiz.nextQuestion')
        : t('quiz.quizOver')}
    </Button>
  );
};

export default QuizButton;
