import { Trans, useTranslation } from 'react-i18next';
import Button from 'src/components/common/designSystem/Button';
import { usePaywall } from 'src/contexts/PaywallContext';
import PrimaryCheckIcon from 'src/components/common/PrimaryCheckIcon';
import { useNavigate } from 'react-router-dom';
import PaywallCGU from 'src/components/Paywall/PaywallCGU';
import { openWhatsAppSupport } from 'src/modules/support/support';
import DuePricing from 'src/components/Paywall/DuePricing';
import IconMdiChevronLeft from '~icons/mdi/chevron-left';
import { RevenueCatOffering } from 'src/modules/purchase/purchase.constants';
import { useCurrentUser } from 'src/queries/user';
import AmbassadorCodeButton from 'src/components/Paywall/AmbassadorCode/AmbassadorCodeButton';
import OfferSelector from 'src/components/Paywall/Offers/OfferSelector';

const PaywallScreenPaySimple = ({
  onValidateAmbassadorCode,
  onPay,
  paying,
  selectedOffer,
  onSelectAnnualOffer,
  onSelectMonthlyOffer,
}: {
  onValidateAmbassadorCode: () => void;
  onPay: () => void;
  paying: boolean;
  selectedOffer:
    | RevenueCatOffering.ANNUAL
    | RevenueCatOffering.SEMI_ANNUAL
    | RevenueCatOffering.MONTHLY;
  onSelectAnnualOffer: () => void;
  onSelectMonthlyOffer?: () => void;
}) => {
  const { t } = useTranslation();
  const { nbTrialDays } = usePaywall();
  const navigate = useNavigate();
  const { data: user } = useCurrentUser();

  if (!user) {
    return null;
  }

  return (
    <div className={'flex flex-col p-5 gap-6'}>
      <div className={'flex flex-row justify-start items-center'}>
        <div
          className={'flex-1 flex justify-start items-center'}
          onClick={() => navigate(-1)}
        >
          <IconMdiChevronLeft fontSize={30} />
        </div>
      </div>

      <div
        className={
          'font-extrabold text-xl text-structural-darkest text-left text-balance'
        }
      >
        <Trans i18nKey="paywall.pay.title">
          <span
            className={
              'bg-gradient-to-r bg-clip-text text-transparent from-paywall-darkest-from to-paywall-darkest-to'
            }
          >
            Essai gratuit
          </span>{' '}
          de Baobab
        </Trans>
      </div>

      <div className={'flex flex-col gap-4 items-start'}>
        <div className={'flex flex-row items-start justify-start gap-3'}>
          <div>
            <PrimaryCheckIcon />
          </div>
          <div className={'text-balance text-base text-structural-darkest'}>
            {t(`paywall.pay.${user.user_type}.item1`, { nbTrialDays })}
          </div>
        </div>
        <div className={'flex flex-row items-start justify-start gap-3'}>
          <div>
            <PrimaryCheckIcon />
          </div>
          <div className={'text-balance text-base text-structural-darkest'}>
            {t(`paywall.pay.${user.user_type}.item2`)}
          </div>
        </div>
        <div className={'flex flex-row items-start justify-start gap-3'}>
          <div>
            <PrimaryCheckIcon />
          </div>
          <div className={'text-balance text-base text-structural-darkest'}>
            {t(`paywall.pay.${user.user_type}.item3`)}
          </div>
        </div>
      </div>

      <OfferSelector
        selectedOffer={selectedOffer}
        onSelectAnnualOffer={onSelectAnnualOffer}
        onSelectMonthlyOffer={onSelectMonthlyOffer}
      />

      <DuePricing />
      {onSelectMonthlyOffer ? null : (
        <div>
          <AmbassadorCodeButton onValidate={onValidateAmbassadorCode} />
        </div>
      )}

      <div className={'flex flex-col w-full gap-2'}>
        <Button
          data-testid={'btn-pay-trial'}
          type={'primary'}
          onClick={onPay}
          loading={paying}
        >
          {t('paywall.pay.button')}
        </Button>
        <Button
          data-testid="btn-contact-us"
          type={'link'}
          onClick={openWhatsAppSupport}
        >
          {t(`paywall.pay.${user.user_type}.contactUs`)}
        </Button>
      </div>

      <PaywallCGU />
    </div>
  );
};

export default PaywallScreenPaySimple;
