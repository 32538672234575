import Star from 'src/components/common/StarReview/Star';

const BaoQuizResultStars = ({ grade }: { grade: number }) => {
  if (grade >= 70) {
    return (
      <>
        <Star className={'h-full w-auto'} isStarred />
        <Star className={'h-full w-auto'} isStarred />
        <Star className={'h-full w-auto'} isStarred />
      </>
    );
  } else if (grade >= 35) {
    return (
      <>
        <Star className={'h-full w-auto'} isStarred />
        <Star className={'h-full w-auto'} isStarred />
        <Star className={'h-full w-auto'} isStarred={false} />
      </>
    );
  }
  return (
    <>
      <Star className={'h-full w-auto'} isStarred />
      <Star className={'h-full w-auto'} isStarred={false} />
      <Star className={'h-full w-auto'} isStarred={false} />
    </>
  );
};

export default BaoQuizResultStars;
