export enum PaywallScreen2Parent {
  paywall_parent_v1 = 'paywall_parent_v1',
  paywall_parent_v2 = 'paywall_parent_v2',
  paywall_parent_6_months = 'paywall-parent-6-months',
}

export type FlagsType = {
  child_trial_store_paywall: boolean;
  display_video_paywall: boolean;
  paywall_screen2_parent: PaywallScreen2Parent;
};
