export default {
  title: 'Profitez de l’expérience Baobab <1>sans limite !</1>',
  tag: 'abonnement',
  item1: {
    title: 'Révisions et quiz illimités !',
  },
  item2: {
    title: 'Jusqu’à 3 enfants par famille',
  },
  item3: {
    title: 'Aucune pub !',
  },
  button: 'Commencer mon offre d’essai',
  description1: '1 semaine d’essai puis 7.99€/mois',
  description2: 'Abonnement valable pour 2 enfants',
  footer:
    "1 semaine d'essai gratuit, annulable à tout moment. Après l'essai, 7,99 €/mois, renouvellement automatique sauf annulation via les paramètres du compte.",
  error: 'Une erreur est survenue. Merci de réessayer.',
  panel: {
    subscribed: {
      parent: {
        title: 'Votre abonnement en cours',
      },
      child: {
        title: 'Ton abonnement en cours',
      },
    },
    notSubscribed: {
      parent: {
        title: 'Aucun abonnement en cours\nPour continuer, abonnez-vous !',
      },
      child: {
        title: 'Aucun abonnement en cours\nPour continuer, abonne-toi !',
      },
    },
    title: 'Abonnement',
    annual: {
      type: 'Abonnement 1 an',
      description: 'Soit <1>{{price}}<1/> par mois',
      promo: 'Promo : {{promo}}',
      threeMonthsFree: '3 mois offerts',
    },
    semiAnnual: {
      type: 'Abonnement 6 mois',
      description: 'Soit <1>{{price}}<1/> par mois',
      promo: 'Promo : {{promo}}',
      oneMonthFree: '1 mois offert',
    },
    type: 'Abonnement mensuel',
    description: 'Sans engagement',
    time: 'par mois',
    footer: 'Annulable depuis les paramètres du compte',
    manage: 'Gérer mon abonnement',
    nextBilling: 'Prochain prélèvement le {{date}}',
    since: 'Abonnement actif depuis le {{date}}',
  },
  locked: {
    title: {
      child: 'Ta période d’essai est terminée Pour continuer, abonne toi !',
      parent:
        'Votre période d’essai est terminée Pour continuer, abonnez-vous !',
    },
    description: 'Votre offre d’essai a expiré',
    button: 'S’abonner',
    footer:
      'Renouvellement automatique de 7,99€/mois sauf annulation via les paramètres du compte.',
  },
  description: {
    parent: {
      title: 'La magie de Baobab <1>en illimité !</1>',
      subtitle: 'Pourquoi les enfants adorent ?',
      item1: {
        title: 'C’est magique',
        description:
          'Des révisions sur-mesure, parfaitement alignées avec les cours de l’enseignant.',
      },
      item2: {
        title: 'C’est fun',
        description:
          'Un apprentissage ludique qui motive les enfants, fini les prises de tête !',
      },
      item3: {
        title: 'C’est rassurant',
        description:
          'Pour les enfants comme pour les parents, tranquillité d’esprit garantie.',
      },
      button: 'Commencer maintenant',
    },
    child: {
      title: 'La magie de Baobab <1>en illimité !</1>',
      subtitle: '<0>{{nbTrialDays}} jours d’essai gratuit</0>',
      subtitle2: 'Faire ses devoirs n’a jamais été aussi stylé !',
      item1: {
        title: 'C’est efficace',
        description: 'Tu révises ce qui va tomber à l’interro ! Génial non?',
      },
      item2: {
        title: 'C’est fun',
        description: 'Tu gagnes des XP en révisant... le rêve!',
      },
      item3: {
        title: 'C’est stylé',
        description: 'Tu connais ton cours... Tes parents sont relax !',
      },
      button: 'Commencer mon essai gratuit',
    },
  },
  pay: {
    title: '<0>Essai gratuit</0> de Baobab',
    parent: {
      item1: '{{nbTrialDays}} jours gratuits, annulez à tout moment',
      item2: 'Vous recevrez un rappel avant la fin de votre période d’essai.',
      item3: 'Jusqu’à 3 enfants par famille !',
      contactUs: 'Une question ? contactez-nous !',
    },
    child: {
      item1: '{{nbTrialDays}} jours gratuits, annule à tout moment',
      item2: 'Tu recevras un rappel avant la fin de ta période d’essai.',
      item3: 'Jusqu’à 3 enfants par famille !',
      contactUs: 'Une question ? contacte-nous !',
    },
    cancel: {
      title: 'Comment annuler ?',
      description1:
        'Super facile ! Depuis l’app Baobab, cliquez sur Paramètres puis Mon abonnement et ”Gérer mon abonnement”.',
      description2:
        'Sélectionnez Baobab puis ”Annuler mon abonnement" et confirmez.',
    },
    paymentDate: 'Dû le {{date}}',
    paymentToday: 'Dû aujourd’hui <1>({{nbTrialDays}} jours gratuits)</1>',
    ambassador_code: {
      button: 'Code ambassadeur',
      panel: {
        title: 'Saississez votre code ambassadeur',
        placeholder: 'Votre code ambassadeur',
        button: 'Valider',
        invalid: 'Code invalide',
        fail: 'Une erreur est survenue. Merci de réessayer.',
      },
    },
    button: 'Commencer l’essai gratuit et s’abonner',
    buttonSkip: 'Je m’abonne plus tard',
    footer:
      'Annulez à tout moment. L’abonnement se renouvelle automatiquement. En vous abonnant, vous acceptez notre <2>politique de confidentialité</2> et nos <5>conditions générales d’utilisation.</5>',
  },
  trial: {
    ending: {
      title: 'Votre période d’essai se termine bientôt !',
      description:
        'Pour continuer à profiter de l’application Baobab, découvrez notre abonnement !',
    },
    ended: {
      title: 'Votre période d’essai est terminée !',
    },
  },
  error_payment: {
    options: {
      title: 'Un problème ? 🫣',
      error: 'A l’aide, problème de paiement !',
      too_expensive: 'Je ne peux pas m’abonner pour 1 an',
      other: 'Autre chose ? Nous contacter',
    },
    too_expensive: {
      title: 'Pas de soucis ! 🤗',
      description1: 'Nous proposons également un abonnement mensuel.',
      description2:
        'Découvrez notre offre et profitez de 7 jours d’essai gratuits !',
      button: 'Voir l’offre mensuelle',
    },
  },
  timeline: {
    item1: {
      title: 'Aujourd’hui',
      description:
        'Obtenez un accès illimité pour découvrir comment Baobab va vous changer la vie !',
    },
    item2: {
      title: 'Jour 5',
      description:
        'Nous vous rappellerons par email ou notification que votre période d’essai expire',
    },
    item3: {
      title: 'Jour {{nbTrialDays}}',
      description:
        'Votre abonnement sera débité le {{trialEndDate}}, vous pouvez annuler à tout moment avant.',
    },
  },
};
