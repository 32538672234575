import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';
import { shuffle } from 'remeda';
import { preloadSoundAsset } from 'src/utils/assets';
import { playSound } from 'src/modules/audio';
import { AnswerQuestionData, useAnswerQuestion } from 'src/queries/quiz/answer';
import { Lesson } from 'src/types/lesson.types';
import { Question, QuizAnswer, QuizType } from 'src/types/quiz.types';

export enum QuestionStatus {
  WAITING = 'waiting',
  PAUSED = 'paused',
  WAITING_FOR_CORRECTION = 'waiting_for_correction',
  ANSWERED_CORRECTLY = 'answered_correctly',
  ANSWERED_INCORRECTLY = 'answered_incorrectly',
  TIMED_OUT = 'timed_out',
}

type QuizContextType = {
  quizType: QuizType;
  lesson: Lesson;
  status: QuestionStatus;
  answersStatusHistory: QuestionStatus[];
  currentQuestion?: Question;
  currentQuestionIndex: number;
  totalQuestions: number;
  canFlagQuestions: boolean;
  nextQuestion: () => void;
  onAnswer: (
    answer: AnswerQuestionData['answer'],
    correct?: boolean,
  ) => Promise<QuizAnswer>;
  onTimeout: () => Promise<void>;
  onPause: () => void;
  onResume: () => void;
  onFlagQuestion: () => void;
};

const QuizContext = createContext<QuizContextType | null>(null);

const QuizProvider = ({
  lesson,
  quizID,
  quizType,
  quizRunID,
  onQuizEnd,
  questionsList,
  children,
  canFlagQuestions = true,
}: PropsWithChildren<{
  lesson: Lesson;
  quizID: number;
  quizType: QuizType;
  quizRunID: number;
  onQuizEnd: () => void;
  questionsList: Question[];
  canFlagQuestions?: boolean;
}>) => {
  const [status, setStatus] = useState<QuestionStatus>(QuestionStatus.WAITING);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const answersStatusHistory = useRef<QuestionStatus[]>([]);
  const nbQuestionFlagged = useRef(0);
  const timeTaken = useRef(Date.now());
  const previousStatus = useRef<QuestionStatus>(QuestionStatus.WAITING);
  const { mutateAsync: sendAnswer } = useAnswerQuestion();
  const questions = useMemo(() => shuffle(questionsList), [questionsList]);
  const currentQuestion: Question | undefined = questions[currentQuestionIndex];

  const nextQuestion = useCallback(() => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(prev => {
        return prev + 1;
      });
      setStatus(QuestionStatus.WAITING);
      timeTaken.current = Date.now();
    } else {
      onQuizEnd();
    }
  }, [currentQuestionIndex, onQuizEnd, questions]);

  const onAnswer = useCallback(
    async (answer: AnswerQuestionData['answer'], isCorrect?: boolean) => {
      const shouldWaitForCorrection = isCorrect === undefined;
      if (shouldWaitForCorrection) {
        setStatus(QuestionStatus.WAITING_FOR_CORRECTION);
        answersStatusHistory.current[currentQuestionIndex] =
          QuestionStatus.WAITING_FOR_CORRECTION;
      } else {
        const newStatus = isCorrect
          ? QuestionStatus.ANSWERED_CORRECTLY
          : QuestionStatus.ANSWERED_INCORRECTLY;
        setStatus(newStatus);

        answersStatusHistory.current[currentQuestionIndex] = newStatus;

        if (isCorrect) {
          playSound('success');
        }
      }

      const apiAnswer = await sendAnswer({
        quizId: quizID,
        runId: quizRunID,
        questionId: currentQuestion.id,
        answer,
        timeTaken: Date.now() - timeTaken.current,
      });

      if (shouldWaitForCorrection) {
        const newStatus = apiAnswer.is_correct
          ? QuestionStatus.ANSWERED_CORRECTLY
          : QuestionStatus.ANSWERED_INCORRECTLY;
        setStatus(newStatus);
        answersStatusHistory.current[currentQuestionIndex] = newStatus;

        if (apiAnswer.is_correct) {
          playSound('success');
        }
      }

      logAnalyticsEvent('quiz_answered', {
        lessonId: lesson.id,
        questionId: currentQuestion.id,
        correct: isCorrect,
        quizType,
      });

      return apiAnswer;
    },
    [
      currentQuestion.id,
      currentQuestionIndex,
      lesson.id,
      quizID,
      quizRunID,
      sendAnswer,
    ],
  );

  const onFlagQuestion = useCallback(() => {
    nbQuestionFlagged.current++;
    nextQuestion();
  }, [nextQuestion]);

  const onTimeout = useCallback(async () => {
    setStatus(QuestionStatus.TIMED_OUT);
    answersStatusHistory.current[currentQuestionIndex] =
      QuestionStatus.TIMED_OUT;

    await sendAnswer({
      quizId: quizID,
      runId: quizRunID,
      questionId: currentQuestion.id,
      timeTaken: Date.now() - timeTaken.current,
    });
  }, [currentQuestion.id, currentQuestionIndex, quizID, quizRunID, sendAnswer]);

  const onPause = useCallback(() => {
    previousStatus.current = status;
    setStatus(QuestionStatus.PAUSED);
  }, [status]);

  const onResume = useCallback(() => {
    setStatus(status => {
      if (status === QuestionStatus.PAUSED) {
        return previousStatus.current;
      }
      return status;
    });
  }, []);

  useEffect(() => {
    preloadSoundAsset({
      assetId: 'success',
      filename: 'success.mp3',
    });
  }, []);

  return (
    <QuizContext.Provider
      value={{
        quizType,
        lesson,
        currentQuestion,
        currentQuestionIndex,
        totalQuestions: questions?.length ?? 0,
        canFlagQuestions,
        status,
        answersStatusHistory: answersStatusHistory.current,
        nextQuestion,
        onAnswer,
        onTimeout,
        onPause,
        onResume,
        onFlagQuestion,
      }}
    >
      {children}
    </QuizContext.Provider>
  );
};

export const useQuiz = () => {
  const quiz = useContext(QuizContext);
  if (!quiz) {
    throw new Error('useQuiz must be used within a QuizContext');
  }
  return quiz;
};

export default QuizProvider;
