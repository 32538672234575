import { useTranslation } from 'react-i18next';
import { useNotification } from 'src/contexts/NotificationContext';
import { useCurrentUser } from 'src/queries/user';
import { useGenerateParentInvitationLink } from 'src/queries/invitations';
import { UserType } from 'src/types/user.types';
import { reportError } from 'src/modules/logs/Sentry';
import { useGetUsers } from 'src/queries/users';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import { useState } from 'react';
import ShareLink from 'src/components/Invitation/ShareLink';
import ProfileItem from 'src/components/Profile/ProfileItem';
import ShareIcon from 'src/images/share.svg?react';
import Spinner from 'src/components/common/designSystem/Spinner';

const InviteParentMenuItem = () => {
  const { t } = useTranslation();
  const { data: user } = useCurrentUser();
  const { data: users, isLoading } = useGetUsers();
  const {
    isLoading: isGenerationInvitationLink,
    mutateAsync: generateInvitationLink,
  } = useGenerateParentInvitationLink();
  const { showError } = useNotification();
  const [{ link, isShareLinkOpen }, setState] = useState<{
    isShareLinkOpen: boolean;
    link?: string;
  }>({
    isShareLinkOpen: false,
  });

  if (!user || user.user_type !== UserType.child || isLoading) {
    return null;
  }

  const parent = users?.find(
    user => user.user_type === UserType.parent && user.firstname,
  );

  if (parent) {
    return null;
  }

  const onClick = async () => {
    try {
      if (isGenerationInvitationLink) {
        return;
      }
      if (link) {
        setState(state => ({
          ...state,
          isShareLinkOpen: true,
        }));
        return;
      }

      const { link: _link } = await generateInvitationLink();
      setState({
        isShareLinkOpen: true,
        link: _link,
      });
    } catch (error: any) {
      reportError('Fail to create parent invitation link', error);
      showError({
        error,
        message: t('invitations.child.parent.error'),
      });
    }
  };

  const onClose = () => {
    setState(state => ({
      ...state,
      isShareLinkOpen: false,
    }));
  };

  return (
    <>
      <ProfileItem
        title={t('invitations.child.parent.button')}
        onClick={onClick}
        data-testid="btn-invite-parent"
        Icon={<ShareIcon className={'fill-structural-darkest w-6'} />}
      />
      <AutoFloatingPanel
        isOpen={isShareLinkOpen}
        onClose={onClose}
        data-testid={'panel-invite-parent'}
      >
        {link ? (
          <ShareLink
            link={link}
            locales={{
              title: t('invitations.child.parent.title'),
              share: {
                description: t('invitations.child.parent.share.description'),
              },
            }}
          />
        ) : (
          <Spinner className="w-full h-full" size="large" />
        )}
      </AutoFloatingPanel>
    </>
  );
};

export default InviteParentMenuItem;
