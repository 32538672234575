import { useCallback, useEffect, useMemo, useState } from 'react';
import QuizFillBlankQuestionHeader from './QuizFillBlankQuestionHeader';
import QuizButton from 'src/components/Quiz/QuizButton';

import { QuestionStatus, useQuiz } from 'src/components/Quiz/QuizContext';
import { shuffle } from 'remeda';
import QuizGuideline from 'src/components/Quiz/QuizGuideline';
import QuizAnswer from 'src/components/Quiz/QuizAnswer';
import { Haptics, NotificationType } from '@capacitor/haptics';
import { ImpactStyle } from '@capacitor/haptics/dist/esm/definitions';
import { FillBlankQuestion } from 'src/types/quiz.types';

const MAX_ANSWERS = 3;

const QuizFillBlankQuestion = ({
  question,
}: {
  question: FillBlankQuestion;
}) => {
  const { onAnswer, status } = useQuiz();
  const [answers, setAnswers] = useState<string[]>([]);
  const [selectedHole, setSelectedHole] = useState<number>(0);
  let splittedQuestion = question.statement.split('___');
  const nbBlanksToFill = splittedQuestion.length - 1;

  const shuffledAnswers = useMemo(() => {
    return question.correct.map((correct, index) => {
      const incorrectAnswers = question.incorrect[index].slice(
        0,
        MAX_ANSWERS - 1,
      );
      return shuffle([correct, ...incorrectAnswers]);
    });
  }, [question.correct, question.incorrect]);

  const onClickAnswer = (index: number, answer: string) => {
    const newAnswers = [...answers];
    newAnswers[index] = answer;
    setAnswers(newAnswers);
    if (index < nbBlanksToFill - 1) {
      Haptics.impact({
        style: ImpactStyle.Light,
      });
      setSelectedHole(index + 1);
    }
    if (newAnswers.length === nbBlanksToFill) {
      validateAnswer(newAnswers);
    }
  };

  const validateAnswer = useCallback(
    async (answers: string[]) => {
      const isCorrect = question.correct.every(
        (correctAnswer, index) => answers[index] === correctAnswer,
      );
      Haptics.notification({
        type: isCorrect ? NotificationType.Success : NotificationType.Error,
      });
      await onAnswer(answers, isCorrect);
    },
    [onAnswer, question.correct],
  );

  useEffect(() => {
    setAnswers([]);
  }, [question.id]);

  return (
    <>
      <div
        className={
          'flex flex-col flex-1 justify-between items-start overflow-hidden'
        }
        data-testid={`question-${question.id}`}
      >
        <QuizFillBlankQuestionHeader
          question={question}
          splittedQuestion={splittedQuestion}
          answers={answers}
          selectedHole={selectedHole}
          onClickHole={setSelectedHole}
        />
        <div
          className={
            'flex flex-col w-full items-center gap-6 bg-white p-6 rounded-t-xl'
          }
        >
          <QuizGuideline />
          <div
            data-testid={'answers'}
            className={'flex flex-col w-full gap-2 text-structural-darker'}
          >
            {shuffledAnswers[selectedHole].map((answer, index) => (
              <QuizAnswer
                key={index}
                id={answer}
                showCorrection={
                  status !== QuestionStatus.WAITING &&
                  status !== QuestionStatus.PAUSED
                }
                isCorrect={question.correct[selectedHole] === answer}
                isSelected={answers[selectedHole] === answer}
                onClick={
                  status === QuestionStatus.WAITING
                    ? () => onClickAnswer(selectedHole, answer)
                    : undefined
                }
              />
            ))}
          </div>
          <QuizButton />
        </div>
      </div>
    </>
  );
};

export default QuizFillBlankQuestion;
