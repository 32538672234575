import LessonLink from 'src/components/LessonDetail/LessonLink';
import { useTranslation } from 'react-i18next';
import { useLesson } from 'src/components/LessonDetail/LessonContext';
import BaoQuizResultStars from 'src/components/Quiz/Result/BaoQuizResultStars';
import { LessonSubject } from 'src/types/lesson.types';

const LessonKorigeLesFotesQuizIcon = () => {
  return <div className={'w-9 h-9 text-4xl'}>🤪</div>;
};
const LessonKorigeLesFotesQuizButton = () => {
  const { t } = useTranslation();
  const { lesson } = useLesson();

  if (
    lesson.subject_matter !== LessonSubject.french ||
    !lesson.latest_korige_les_fotes_quiz
  ) {
    return null;
  }

  const runsNormalQuiz = lesson.latest_quiz?.runs;
  const maxScoreNormalQuiz = runsNormalQuiz?.length
    ? Math.max(...runsNormalQuiz.map(run => run.grade))
    : undefined;

  const runs = lesson.latest_korige_les_fotes_quiz?.runs;
  const maxScore = runs?.length
    ? Math.max(...runs.map(run => run.grade))
    : undefined;

  const disabled = !maxScoreNormalQuiz || maxScoreNormalQuiz < 80;

  return (
    <LessonLink
      title={
        <div className={'flex flex-row justify-between items-center gap-2'}>
          <div>{t('lessons.details.korige_les_fotes_quiz')}</div>
          {maxScore ? (
            <div className={'flex flex-row gap-1 h-6 pr-2'}>
              <BaoQuizResultStars grade={maxScore} />
            </div>
          ) : null}
        </div>
      }
      link={`/lessons/${lesson.id}/quizzes/${lesson.latest_korige_les_fotes_quiz.id}`}
      IconComponent={LessonKorigeLesFotesQuizIcon}
      data-testid={'link-korige-les-fotes-quiz'}
      disabled={disabled}
      disabledDescription={t('lessons.details.bao_quiz_disabled')}
    />
  );
};

export default LessonKorigeLesFotesQuizButton;
