import { useTranslation } from 'react-i18next';
import { usePaywall } from 'src/contexts/PaywallContext';
import { PURCHASES_ERROR_CODE } from '@revenuecat/purchases-capacitor';
import { useEffect, useState } from 'react';
import { useNotification } from 'src/contexts/NotificationContext';
import { reportError } from 'src/modules/logs/Sentry';
import { RevenueCatOffering } from 'src/modules/purchase/purchase.constants';
import { useFlags } from 'src/contexts/FlagsContext';
import PaywallScreenPayFull from 'src/components/Paywall/PaywallScreenPay/PaywallScreenPayFull';
import PaywallScreenPaySimple from 'src/components/Paywall/PaywallScreenPay/PaywallScreenPaySimple';
import { PaywallScreen2Parent } from 'src/types/flags.types';

const PaywallScreenPay = ({
  showMensualOffer,
  onValidateAmbassadorCode,
  onPaymentError,
}: {
  showMensualOffer: boolean;
  onValidateAmbassadorCode: () => void;
  onPaymentError?: (error: any) => void;
}) => {
  const { t } = useTranslation();
  const { subscribePremium } = usePaywall();
  const [selectedOffer, setSelectedOffer] = useState<
    | RevenueCatOffering.ANNUAL
    | RevenueCatOffering.SEMI_ANNUAL
    | RevenueCatOffering.MONTHLY
  >(RevenueCatOffering.ANNUAL);
  const [paying, setPaying] = useState(false);
  const { showError } = useNotification();
  const { paywall_screen2_parent } = useFlags();

  const pay = async () => {
    try {
      setPaying(true);
      await subscribePremium(selectedOffer);
    } catch (error: any) {
      if (onPaymentError) {
        onPaymentError(error);
      }

      if (error.code === PURCHASES_ERROR_CODE.PURCHASE_CANCELLED_ERROR) {
        setPaying(false);
        return;
      }

      reportError('Fail to subscribe premium with trial', error);
      showError({
        error,
        message: t('paywall.error'),
      });
    }
    setPaying(false);
  };

  useEffect(() => {
    if (
      paywall_screen2_parent === PaywallScreen2Parent.paywall_parent_6_months
    ) {
      setSelectedOffer(RevenueCatOffering.SEMI_ANNUAL);
    }
  }, [paywall_screen2_parent]);

  if (paywall_screen2_parent === PaywallScreen2Parent.paywall_parent_6_months) {
    return (
      <PaywallScreenPayFull
        onValidateAmbassadorCode={onValidateAmbassadorCode}
        onPay={pay}
        paying={paying}
        selectedOffer={selectedOffer}
        onSelectSemiAnnualOffer={
          paywall_screen2_parent ===
          PaywallScreen2Parent.paywall_parent_6_months
            ? () => setSelectedOffer(RevenueCatOffering.SEMI_ANNUAL)
            : undefined
        }
        onSelectMonthlyOffer={
          showMensualOffer
            ? () => setSelectedOffer(RevenueCatOffering.MONTHLY)
            : undefined
        }
      />
    );
  }

  if (paywall_screen2_parent === PaywallScreen2Parent.paywall_parent_v2) {
    return (
      <PaywallScreenPayFull
        onValidateAmbassadorCode={onValidateAmbassadorCode}
        onPay={pay}
        paying={paying}
        selectedOffer={selectedOffer}
        onSelectAnnualOffer={() => setSelectedOffer(RevenueCatOffering.ANNUAL)}
        onSelectMonthlyOffer={
          showMensualOffer
            ? () => setSelectedOffer(RevenueCatOffering.MONTHLY)
            : undefined
        }
      />
    );
  }

  return (
    <PaywallScreenPaySimple
      onValidateAmbassadorCode={onValidateAmbassadorCode}
      onPay={pay}
      paying={paying}
      selectedOffer={selectedOffer}
      onSelectAnnualOffer={() => setSelectedOffer(RevenueCatOffering.ANNUAL)}
      onSelectMonthlyOffer={
        showMensualOffer
          ? () => setSelectedOffer(RevenueCatOffering.MONTHLY)
          : undefined
      }
    />
  );
};

export default PaywallScreenPay;
